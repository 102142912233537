import { User } from 'app/core/models/user';
import { Injectable } from '@angular/core';
import { CosgridAppStore } from 'app/store/app.store';
import { Store } from '@ngrx/store';
import { SET_CURRENT_USER } from 'app/store/actions/shared.actions';
import { selectCurrentUser } from 'app/store/selectors/shared.selector';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private userEmail: String;
    private currentUser;

    constructor(
        private store: Store<CosgridAppStore>,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
    ) {
        this.store.select(selectCurrentUser).subscribe((user) => {
            this.currentUser = user;
        });
    }

    setUser(user: User) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem("currentUser", JSON.stringify(user));
        this.encryptedLocalStorageService.setItem('currentUser', JSON.stringify(user));

        this.store.dispatch(new SET_CURRENT_USER(user));
        this.setUserEmail(user.email);
    }

    getUser(): User {
        return this.currentUser || (this.encryptedLocalStorageService.getItem('currentUser') as User);
        // get the current user from local storage to keep user logged in between page refreshes
    }

    clearUser() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('deviceState');
        localStorage.removeItem('selectedDevice');
        localStorage.removeItem('ntopinterface');
        this.deleteCookie('cosgridToken');
    }

    setToken(token: string) {
        const now = new Date();
        const expires = now.setDate(now.getDate() + 7); // expires in 7 days
        document.cookie = `cosgridToken=${token}; expires=${new Date(expires).toUTCString()}; path=/`;
    }

    getToken() {
        return this.getCookie('cosgridToken');
    }

    // emailID
    // when we get the email id from the url params on redirect to userDomain.cosgrod.com from app.cosgrid.com
    setUserEmail(email) {
        this.encryptedLocalStorageService.setItem('userEmail', email);
        // localStorage.setItem("userEmail", email);
    }

    removeUserEmail() {
        this.encryptedLocalStorageService.removeItem('userEmail');
        // localStorage.removeItem('userEmail');
    }
    getUserEmail() {
        return this.encryptedLocalStorageService.getItem('userEmail');
        // return localStorage.getItem("userEmail");
    }

    getUserInfo(email: string) {
        const defaults = {
            name: '',
            companyName: '',
        };
        try {
            if (email) {
                const [name, domain] = email.split('@');
                const [companyName] = domain.split('.');
                return {
                    name,
                    companyName,
                };
            }
            return defaults;
        } catch (error) {
            return defaults;
        }
    }

    // setCookie(name: string, value: string, days: number) {
    //     let expires = '';
    //     if (days) {
    //         var date = new Date();
    //         date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    //         expires = '; expires=' + date.toUTCString();
    //     }
    //     document.cookie = name + '=' + value || '' + expires + '; path=/';
    // }

    deleteCookie(name) {
        document.cookie = 'cosgridToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    getCookie(name: string) {
        let nameEQ = name + '=';
        let ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }
}
