import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from './../../../store/app.store';
import { CheckConnectionService } from '../misc/check-connection.service';
import { UserService } from './user.service';
import { Subject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/configs/constants';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { DeviceListService } from 'app/shared/services/comp';

@Injectable({
    providedIn: 'root',
})
export class WebsocketService {
    ws;
    dataEmitter: Subject<any> = new Subject();
    immedieateFallback: Subject<any> = new Subject();
    retryCount = 0;
    retryInterval = 10000;

    // logging
    tennatName = this.customerListService.getTenantName();
    isRequiredId: boolean; // this.customerListService.getTenantId() === "2"?true:false
    additionalInfo;
    selectedNetwork;
    constructor(
        private globalConstants: GlobalConstants,
        private userService: UserService,
        private customerListService: CustomerListService,
        private checkConnection: CheckConnectionService,
        private deviceService: DeviceListService,
        private store: Store<CosgridAppStore>,
        private router: Router,
    ) {
        // will establish the connection when user open the site for 1st time or reload the page
        this.connect();
        this.subscribeToDataEmitter();
        this.subscribeToSelectedNetwork();
    }

    connect() {
        this.ws = webSocket(this.globalConstants.ws_tenant);
        // this.ws.next({tenant_name:this.customerService.getTenantName(),
        //                 tenant_id:this.customerService.getTenantId()})
        // this.logger.info('websokcet connected',this.tennatName)
    }

    //  if the client has internet and there is a actual problem in the backend, the client will be keep on trying on stack all the requests and the backend has to answer all the requests
    esablishConnection() {
        // to reestablish the connection when the user logs out and login again
        this.connect();
        this.subscribeToDataEmitter();
    }
    establishToken() {
        this.ws.next({ token: this.userService.getToken() });
    }

    subscribeToDataEmitter() {
        this.ws.subscribe(
            (res) => {
                this.dataEmitter.next(res);
            },
            (err) => {
                // this.dataEmitter.next({error:err});
                this.retryCount++;
                this.retry(err);
            },
            () => {
                // this.dataEmitter.complete() do not complete the data emitter
            },
        );
    }

    retry(err) {
        setTimeout(() => {
            if (navigator.onLine) {
                if (this.checkConnection.internetState) {
                    this.fallBackHttp();
                    this.esablishConnection();
                    this.establishToken();
                } else {
                    this.retry(err);
                    this.dataEmitter.next({ error: err });
                }
            } else {
                this.retry(err);
            }
        }, this.retryInterval);
    }

    fallBackHttp() {
        if (this.router.url.includes('dashboard')) {
            // only try getting the data if the user if on dashboard page
            let tid_data = {
                tenant_id: this.customerListService.getTenantId() || '',
                network_id: this.selectedNetwork.id,
            };
            let tname_data = {
                tenantname: this.customerListService.getTenantName() || '',
                network_id: this.selectedNetwork.id,
            };
            let deviceList = this.deviceService.getDeviceList(tid_data).toPromise();
            let tenantInfo = this.customerListService.getTenantInfo(tname_data).toPromise();
            Promise.all([deviceList, tenantInfo])
                .then((res) => {
                    this.dataEmitter.next({
                        deviceinfo: res[0],
                        tenantinfo: res[1],
                    });
                })
                .catch((err) => {
                    this.dataEmitter.next({
                        error: err,
                    });
                });
        }
    }
    subscribeToSelectedNetwork() {
        this.store
            .select((slices) => slices.networkSlice.selectedNetwork)
            .subscribe((res) => {
                if (res) {
                    // getdevicelist call is in changeNetwork() cuz network cannot be changed except here
                    // device selection deleted when the user literally toggles the selection and not when the network value changes
                    this.selectedNetwork = res;
                }
            });
    }

    getDataEmitter(): Subject<any> {
        return this.dataEmitter;
    }
    getWebSocket(): Subject<any> {
        return this.ws;
    }

    postDataInWs(data) {
        this.ws.next(data);
    }

    terminateConnection() {
        this.ws.complete();
        this.ws.unsubscribe();
        // this.dataEmitter.complete()
    }
}
