import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { defaultProps, IRowConfig, legend, tableProps } from '../table/utills/defaultProps';
import { TableService } from '../../services/comp/table.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
    selector: 'cosgrid-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
    @Input() heading: string;
    @Input() uTableId: string = this.defaultProps.uTableid; // unique table ID, if two or more tables or used in a single component
    @Input() rows: Array<any> = [];
    @Input() rowProps: Array<IRowConfig>;
    @Input() nullMessage = 'No data';
    @Input() isConfig = true; // if it is configuration table or not
    @Input() type: 'configuration' | 'status' | 'networks' | 'user' | 'configTemp' = 'configuration';
    @Input() refreshLoading = false;
    @Input() dropdownValues: Array<any> = [];
    @Input() legends: Array<legend> = this.defaultProps.legends;
    @Input() tableProps: tableProps = this.defaultProps.tableProps;
    @Input() needButton: boolean = false;
    @Input() buttonName: string = '';
    @Input() needSerialNumbers: boolean = false; //if set true, s.no comes in table
    @Input() needStatus: boolean = false; //if set true, status with color will come based on select, total attribute
    @Input() needCheckbox: boolean = false;
    @Input() statusBoxHeader: string = 'Selected'; // add this to create a custom header for status box
    @Input() needStatusBoolean: boolean = false; //status with color based on 'status' attibute
    @Input() needStatusCheckbox: boolean = false; //if set true, ticked checkbox will come if status true and unticked on status false
    @Input() dropdownHeaders: Array<string> = [];
    @Input() multiSelectNeeded: boolean = false;
    @Output() add = new EventEmitter<any>();
    @Output() view = new EventEmitter<any>();
    @Output() edit = new EventEmitter<any>();
    @Output() delete = new EventEmitter<any>();
    @Output() toggled = new EventEmitter<any>();
    @Output() refreshed = new EventEmitter<any>();
    @Output() onRowClick = new EventEmitter<any>();
    @Output() onCheckboxClick = new EventEmitter<any>(); //needStatusCheckbox sets checkboxes. On click checkbox, event emitted
    @Output() onButtonClick = new EventEmitter<any>();
    @Output() onSelectAll = new EventEmitter<any>();
    // local variables;

    searchText = '';
    sortParams: any = {
        ev: '',
    };
    needToggle = true; // if parent componenet needs toggle functionality in 'Actions'
    needRowClick = false; // if parent componenet needs row click functionality in 'Actions'
    needRowAdd = true; // if parent componenet needs Add Row functionality in 'Actions'
    noMatch = false;
    needEdit = true;
    needDelete = true;
    needView = true;
    noRows = false;
    constructor(private defaultProps: defaultProps, public tableService: TableService) { }
    ngOnInit() {
        // if( this.dropdownHeaders.length !== this.dropdownValues.length) {
        //     const extra = this.dropdownValues.length - this.dropdownHeaders.length
        // }
        this.needToggle = this.toggled.observers.length > 0 ? true : false;
        this.needRowClick = this.onRowClick.observers.length > 0 ? true : false;
        this.needRowAdd = this.tableService.needFunctionality(this.add);
        this.needEdit = this.edit.observed; //check if (edit) given in parent component. if given, return true
        this.needDelete = this.delete.observed;
        this.needView = this.view.observed;
        console.log(this.rows);
    }
    ngOnChanges(changes: SimpleChanges) {
        try {
            // apply all the operations user did before refresh, after refresh
            if ('refreshLoading' in changes) {
                this.refreshLoading = changes['refreshLoading'].currentValue;
                if (!this.refreshLoading) {
                    this.sortRows(this.sortParams.rowProp, this.sortParams.order, this.sortParams.ev);
                    // delay for table to render
                    setTimeout(() => {
                        this.searchRows();
                    }, 100);
                }
            }
        } catch (err) { }
        // parseRows - fill all the default values in place of no values
        try {
            if (changes['rowProps']) {
                this.rowProps = this.rowProps.map((inp) => {
                    return {
                        ...this.defaultProps.rowProps,
                        ...inp,
                    };
                });
            }
        } catch (err) { }
        try {
            if (changes['rows']) {
                // check whether values came
                this.noRows = this.rows.length == 0; // finding whether it is the first row
                this.rowProps = this.rowProps.map((inp) => {
                    if (inp.type === 'icon') {
                        this.rows.map((row) => {
                            this.legends.map((legend) => {
                                if (row[inp.key] == legend.key) {
                                    row['temp' + inp.key] = legend.iconName;
                                } // adding temp keyword to not to modify the original key value and use temp+keyName to render icons
                            });
                        });
                        return inp;
                    }
                    return inp; // if it is string return it directly
                });
            }
        } catch (err) { }
    }
    // rowClick
    rowClick(row, ev) {
        function makeAllFalse(tableID) {
            // make everything false;
            const allCheckboxes = document.querySelectorAll(tableID + ' input[class="checkbox"]');
            allCheckboxes.forEach((checkbox: any) => {
                checkbox.checked = false;
            });
        }
        if (this.needRowClick) {
            // to avoid unnecessary computation if rowclick is not needed
            let val;
            if (ev.srcElement.className === 'fas fa-trash' || ev.srcElement.className === 'fa fa-pencil-square-o') {
                //  do nothing if they press edit or delete
            } else {
                if (ev.srcElement.type == 'checkbox') {
                    // if it is a checkbox, it will automatically change the value itself
                    const temp = ev.srcElement.checked; // store the value of checkbox in a temp variable
                    makeAllFalse(this.uTableId); // make all the values false to maintain intergrity between checkbox click and row click;
                    ev.srcElement.checked = temp; // make a the value to its actual value
                    val = temp; // store the value in val
                } else {
                    const closesCheckbox = ev.srcElement
                        .closest('tr')
                        .querySelectorAll(this.uTableId + ' input[class="checkbox"]')[0].checked; // get the closest checkbox from clicked row position
                    if (closesCheckbox) {
                        // if it is checked
                        ev.srcElement.closest('tr').getElementsByTagName('input')[0].checked = !closesCheckbox; // invert the value
                        val = ev.srcElement
                            .closest('tr')
                            .querySelectorAll(this.uTableId + ' input[class="checkbox"]')[0].checked; // store it in val
                    } else {
                        // if it is not checked
                        makeAllFalse(this.uTableId);
                        ev.srcElement.closest('tr').getElementsByTagName('input')[0].checked = !ev.srcElement
                            .closest('tr')
                            .getElementsByTagName('input')[0].checked; // invert the value
                        val = ev.srcElement
                            .closest('tr')
                            .querySelectorAll(this.uTableId + ' input[class="checkbox"]')[0].checked; // store it in val
                    }
                }
                this.onRowClick.emit({ row, val });
            }
        }
    }

    // selectAllRow
    selectAllRow(event: any) {
        const checkbox = event.target as HTMLInputElement;
        this.onSelectAll.emit({ event, multiselected: checkbox.checked });
    }

    selectRow(event: Event, row: any): void {
        const checkbox = event.target as HTMLInputElement;
        row.select = checkbox.checked;
    }
    // sort
    sortRows(rowProp, order, ev) {
        this.sortParams = { rowProp, order, ev }; // store the params to use it when user refreshes
        if (this.sortParams.ev !== '') {
            // to avoid sorting during refresh and when no sortconfig is set
            setTimeout(() => {
                this.tableService.aciveIcon(ev);
            }, 200);
            this.rows = rowProp.sort(rowProp.key, order, ev, this.rows);
        }
        // this.rows = this.tableService.sortRows(key, order, ev, this.rows)
    }
    // refresh
    refreshRowData() {
        this.refreshLoading = true;
        this.refreshed.emit(true);
    }
    // download
    downloadAllRows() {
        this.tableService.exportTableToCSV(this.heading, this.isConfig, this.uTableId);
    }
    // search
    searchRows() {
        try {
            let filter,
                table,
                tr,
                td,
                i,
                j,
                txtValue,
                check = false,
                trLength,
                tdLength,
                invisibleRows = 0;
            filter = this.searchText.toUpperCase();
            table = document.getElementById(this.uTableId);
            tr = table.querySelectorAll('tbody tr');
            trLength = tr.length;
            for (i = 0; i < trLength; i++) {
                td = tr[i].getElementsByTagName('td');
                check = false;
                if (this.isConfig) {
                    tdLength = td.length - 1; // to avoid looking for searchText in actions column wch has only buttons
                } else {
                    tdLength = td.length;
                }
                for (j = 0; j < tdLength; j++) {
                    if (td[j]) {
                        txtValue = td[j].innerText;
                        if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            check = true;
                            break;
                        } else {
                            check = false;
                        }
                    }
                }
                if (check) {
                    tr[i].style.display = '';
                } else {
                    tr[i].style.display = 'none';
                }
                if (tr[i].style.display === 'none') {
                    invisibleRows++;
                }
            }
            this.noMatch = invisibleRows === trLength;
        } catch (err) {
            // no rows in the table
        }
    }
    clearSearch() {
        this.searchText = '';
        this.searchRows();
    }

    // dataStyle
    getDataStyle(row, rowProp) {
        if (rowProp.dataStyle) {
            if (typeof rowProp.dataStyle === 'function') {
                return rowProp.dataStyle(row, rowProp.key);
            }
            return rowProp.dataStyle;
        }
        return {}; // Return an empty object or a default style if dataStyle is not provided
    }

    getValueModel(row, rowProp) {
        if (rowProp.valueStyle) {
            if (typeof rowProp.dataStyle === 'function') {
                return rowProp.valueModel(row, rowProp.key);
            }
            return rowProp.dataStyle;
        }
        return null
    }
    // datatable Actions
    addRow() {
        this.add.emit();
    }
    // Actions
    toggledEnableDisable(ev, item) {
        this.toggled.emit({ ev, item }); // 'item' refers to row data
    }
    editRow(ev) {
        this.edit.emit(ev);
    }
    viewRow(ev) {
        this.view.emit(ev);
    }

    chooseOption(ev, item) {
        item[ev.target.name] = ev.target.value;
    }
    modifyInput(ev, item) {
        item[ev.target.name] = ev.target.value;
    }
    deleteRow(ev) {
        this.delete.emit(ev);
    }

    buttonClick(ev, row) {
        this.onButtonClick.emit({ ev, row });
    }
    //  End of Actions
    formatValue(value: string | number, rowProp: IRowConfig) {
        if (rowProp.valueFormatter) {
            return rowProp.valueFormatter(value) ?? value;
        }
        return value[rowProp.key] ?? value;
    }

    clickCheckbox(ev, row) {
        this.onCheckboxClick.emit({ event: ev, row: row });
    }

    drop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    }
}
