import { AnnouncementsComponent } from './core/components/announcements/announcements.component';
import { GetMicroZaccessComponent } from './core/components/get-micro-zaccess/get-micro-zaccess.component';
import { DashboardComponent } from 'app/modules/dashboard/dashboard.component';
import { Routes } from '@angular/router';
import { LoaderComponent, NotFoundComponent } from 'app/shared';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { SuperAdminAuthGuard } from 'app/modules/super-admin/guards/super-admin.guard';
import { RouteConstants } from 'app/configs/constants';
import { DeviceSelectGuard } from 'app/core/guards/deviceselect.guard';
import { TicketRaiseComponent } from 'app/core/components/header/components/support/components/ticket-raise/ticket-raise.component';
import { TrackTicketStatusComponent } from 'app/core/components/header/components/support/components/track-ticket-status/track-ticket-status.component';
import { TenantSettingsComponent } from 'app/modules/settings/components/tenant-settings/tenant-settings.component';
import { RUMComponent } from './modules/rum/rum.component';
import { SomethingWentWrongComponent } from 'app/shared/components/something-went-wrong/something-went-wrong.component';

export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: RouteConstants.AUTH,
        pathMatch: 'full',
    },

    {
        path: RouteConstants.AUTH,
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
        data: { preload: true },
    },
    {
        path: RouteConstants.SUPER_ADMIN,
        loadChildren: () => import('./modules/super-admin/super-admin.module').then((m) => m.SuperAdminModule),
        // canActivate: [AuthGuard, SuperAdminAuthGuard],
        data: { preload: true },
    },
    {
        path: RouteConstants.DASHBOARD,
        component: DashboardComponent,
    },
    {
        path: RouteConstants.SD_WAN_CONFIGURE,
        loadChildren: () =>
            import('./modules/sd-wan-gateway/sub-modules/configure/sd-wan-configuration.module').then(
                (m) => m.SdWanConfigurationModule,
            ),
        // canActivate: [AuthGuard, DeviceSelectGuard],
        data: { preload: true },
    },
    {
        path: RouteConstants.SD_WAN_STATUS,
        loadChildren: () =>
            import('./modules/sd-wan-gateway/sub-modules/status/sd-wan-status.module').then((m) => m.SdWanStatusModule),
        // canActivate: [AuthGuard, DeviceSelectGuard],
        data: { preload: true },
    },
    {
        path: RouteConstants.MZA_STATUS,
        loadChildren: () => import('./modules/microZAccess/status/mza-status.module').then((m) => m.MzaStatusModule),
    },
    {
        path: RouteConstants.NETWORK,
        loadChildren: () => import('./modules/network/network.module').then((m) => m.NetworkModule),
        // canActivate: [AuthGuard],
        data: { preload: true, delay: true },
    },
    {
        path: RouteConstants.NDR,
        loadChildren: () => import('./modules/ndr/ndr.module').then((m) => m.NdrModule),
        data: { preload: true },
    },
    {
        path: RouteConstants.TEAMS,
        loadChildren: () => import('./modules/team/teams.module').then((m) => m.TeamsModule),
    },
    {
        path: RouteConstants.LOGS,
        loadChildren: () => import('./modules/logs/logs.module').then((m) => m.LogsModule),
    },
    {
        path: RouteConstants.ORGANIZATION,
        loadChildren: () => import('./modules/organization/organization.module').then((m) => m.OrganizationModule),
        // canActivate: [AuthGuard],
        data: { preload: true, delay: true },
    },
    {
        path: RouteConstants.SETTINGS,
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        // canActivate: [AuthGuard],
        data: { preload: true, delay: true },
    },

    // misc components
    { path: 'getmicrozaccess', component: GetMicroZaccessComponent },
    { path: 'tenant-settings', component: TenantSettingsComponent },
    { path: 'support/ticketRaise', component: TicketRaiseComponent },
    { path: 'support/trackTicket', component: TrackTicketStatusComponent },
    { path: 'announcements', component: AnnouncementsComponent },
    { path: 'loading', component: LoaderComponent, canActivate: [AuthGuard] },
    { path: '404', component: NotFoundComponent },
    { path: RouteConstants.SOMETHING_WENT_WRONG, component: SomethingWentWrongComponent },
    { path: 'RUM', component: RUMComponent },

    // otherwise redirect to Not Found page
    { path: '**', redirectTo: '404' },
];
