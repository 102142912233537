<ng-container *ngIf="dashbaordConf?.vpnDashboard; else elseTemplate">
    <div class="row ml-1">
        <div id="dashboard" style="flex: 1;">
            <cosgrid-tile-group [tiles]="tiles"></cosgrid-tile-group>
        </div>
        <div class="col" style="flex: 5; margin-left: 15px;padding-right: 0;">
            <map [devices]="deviceList"></map>
        </div>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div id="dashboard">
        <cosgrid-tile-group [tiles]="tiles"></cosgrid-tile-group>
    </div>
    <div class="row">
        <div class="col" style="margin-top: 5px;">
            <map [devices]="deviceList"></map>
        </div>
        <div *ngIf="showGraph" class="col" style="padding-left: 0px !important;padding-right: 0;">
            <div id="graph" class="card" style="margin-top: 15px;">
                <cosgrid-top-devices [networkChange]="selectedNetwork"></cosgrid-top-devices>
            </div>
        </div>
    </div>
</ng-template>

<!--end of row-->
<div class="row ml-3 mb-2">
    <device-card [dashboardConf]="dashbaordConf"></device-card>
</div>