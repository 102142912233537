<div style="width: 60px;"></div>
<!-- (mouseover)="openSideBar()" (mouseout)="closeSideBar()"  -->

<mat-card (mouseover)="openSideBar()" (mouseout)="closeSideBar()" (mouseleave)="two = true; one = true"
    [style.width.px]="sidebarwidth" class="sidebarWrappercollapsed">
    <div class="menucontent">
        <div class="menuStart">
            <a *ngIf="isTenantSelected" joyrideStep="home" matTooltipPosition="right" title="Dashboard"
                text="Dashboard contains organization level information about all the devices and its corresponding status"
                class="mainlist" routerLink="/dashboard" routerLinkActive="active">
                <mat-icon svgIcon="dashboard" class="material-icons" aria-setsize="10px"></mat-icon><span
                    class="optionNames"> Dashboard </span>
            </a>
            <a *ngIf="!isTenantSelected" joyrideStep="home" matTooltipPosition="right" title="Dashboard"
                text="Dashboard contains organization level information about all the devices and its corresponding status"
                class="mainlist" (click)="showSelectTenant()">
                <mat-icon>home</mat-icon><span class="optionNames">Tenant Dashboard </span>
            </a>
        </div>
        <ng-container *ngIf="vpnDashboard; else sdwantemplate">
            <div>
                <a *ngIf="selectedDevice" joyrideStep="microzaccess" title="MicroZAccess Endpoint Profiles"
                    matTooltipPosition="right"
                    text="Creation of endpoint profiles for respective devices with specific accessibility"
                    routerLink="/device/configure/overlay-mesh-vpn" routerLinkActive="active" id="sdwangatewayselected"
                    style="max-height: 25px;" class="mainlist">
                    <mat-icon svgIcon="microzaccess" class="material-icons"></mat-icon>
                    <span class="optionNames">MicroZAccess &nbsp; &nbsp; </span>
                </a>
                <a *ngIf="!selectedDevice" joyrideStep="microzaccess" title="MicroZAccess Endpoint Profiles"
                    matTooltipPosition="right"
                    text="Creation of endpoint profiles for respective devices with specific accessibility"
                    id="sdwangatewayselected" style="max-height: 25px;" class="mainlist"
                    (click)="showDeviceNotSelected()">
                    <mat-icon svgIcon="microzaccess" class="material-icons"></mat-icon>

                    <span class="optionNames">MicroZAccess &nbsp; &nbsp; </span>
                </a>
            </div>
        </ng-container>
        <ng-template #sdwantemplate>
            <div (mouseover)="two = false; one = true" *ngIf="selectedDevice">
                <a joyrideStep="sdWan" title="SD-WAN Gateway" matTooltipPosition="right"
                    text="SD-Wan gateway contains all the configuration options for the selected device"
                    routerLink="/device/configure" routerLinkActive="active" id="sdwangatewayselected"
                    style="max-height: 25px;" class="mainlist">
                    <div class="d-inline-flex">
                        <mat-icon svgIcon="sdWanSide" class="material-icons"></mat-icon><span class="optionNames">SD-WAN
                            Gateway &nbsp; &nbsp; </span>
                    </div>
                    <mat-icon><i style="font-size: 15px;" class="fa fa-chevron-right" aria-hidden="true">
                        </i></mat-icon>
                </a>
                <div class="dropdown" *ngIf="!two" style="opacity: 1;">
                    <div class="dropdowninner">
                        <h5 class="ml-4">Configure</h5>
                        <hr class="type1" />
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/addressing-vlan">
                            <mat-icon>dns</mat-icon>Interfaces & VLANs
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/staticroutes">
                            <mat-icon>compare_arrows</mat-icon>Static Routes
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/sdwan">
                            <mat-icon>call_split</mat-icon>SD-WAN
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/vpn-creation">
                            <mat-icon>vpn_key</mat-icon>IPSec VPN
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/firewall">
                            <mat-icon>security</mat-icon>Firewall
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/link-bonding">
                            <mat-icon>link</mat-icon>Link Bonding
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/network-acceleration">
                            <mat-icon>call_split</mat-icon> Network Acceleration
                        </a>

                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/qos">
                            <mat-icon>important_devices</mat-icon>QoS
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/services">
                            <mat-icon>bubble_chart</mat-icon>Services
                        </a>
                    </div>
                    <div class="dropdowninner">
                        <h5>&nbsp;<br /></h5>
                        <hr />
                        <!-- <a (click)="two=true;one=true" routerLink="/device/configure/admin" mat-button>
                    <mat-icon>settings</mat-icon>Admin</a> -->
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/overlay-mesh-vpn">
                            <mat-icon>transform</mat-icon>Overlay Mesh VPN
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" routerLink="/device/configure/wireless"
                            mat-button>
                            <mat-icon>wifi</mat-icon>Wireless
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/bgp">
                            <mat-icon>linear_scale</mat-icon>BGP
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" routerLink="/device/configure/policyrouting"
                            mat-button>
                            <mat-icon>swap_calls</mat-icon>Policy Routing
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/content-filter">
                            <mat-icon>filter_list</mat-icon>Content Filtering
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/url-filter">
                            <mat-icon>linear_scale</mat-icon>URL Filtering
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/suricata">
                            <mat-icon>linear_scale</mat-icon>IDS/IPS
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" routerLink="/device/configure/netflowconfig"
                            mat-button>
                            <mat-icon>pie_chart</mat-icon>Netflow
                        </a>
                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/nat">
                            <mat-icon>transform</mat-icon>NAT
                        </a>

                        <a (click)="two = true; one = true; closeSideBar()" mat-button
                            routerLink="/device/configure/diagnostics">
                            <mat-icon>perm_data_setting</mat-icon>Diagnostics
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <a joyrideStep="sdWan" title="SD-WAN Gateway" matTooltipPosition="right"
                    text="SD-Wan gateway contains all the configuration options for the selected device"
                    (click)="showDeviceNotSelected()" id="sdwangateway" style="max-height: 25px;" class="mainlist"
                    *ngIf="!selectedDevice">
                    <div class="d-inline-flex">
                        <mat-icon svgIcon="sdWanSide" class="material-icons"></mat-icon><span class="optionNames">SD-WAN
                            Gateway &nbsp; &nbsp; </span>
                    </div>
                </a>
            </div>
            <div *ngIf="selectedDevice">
                <a joyrideStep="globalMonitor" title="Monitor" matTooltipPosition="right"
                    text="Contains all the Configuration Info, Usage Info for a selected device " class="mainlist"
                    routerLink="/device/status" [ngClass]="{ active: active }">
                    <mat-icon svgIcon="monitor" class="material-icons"></mat-icon><span class="optionNames"> Monitor
                    </span>
                </a>
            </div>
        </ng-template>

        <!-- <div>
            <a joyrideStep="networks" title="Networks" matTooltipPosition="right"
                text="Contains Configuration of Networks, Adding/Removing of devices from or to a network" id="network"
                class="mainlist" routerLink="/network" routerLinkActive="active">
                <mat-icon class="material-icons" svgIcon="network"></mat-icon><span class="optionNames">Device Groups
                    &nbsp; &nbsp; </span>
            </a>
        </div> -->
        <!-- <div>
            <a joyrideStep="teams" title="Teams" matTooltipPosition="right"
                    text="SD-Wan gateway contains all the configuration options for the selected device"
                    routerLink="/teams" routerLinkActive="active" id="teams"
                    style="max-height: 25px;" class="mainlist">
                    <div class="d-inline-flex">
                        <mat-icon svgIcon="teams" class="material-icons"></mat-icon><span class="optionNames">Teams&nbsp; &nbsp; </span>
                    </div>
                </a>
        </div> -->
        <div>
            <a joyrideStep="teams" title="Teams" text="Organization level Configuring and Monitoring"
                matTooltipPosition="right" class="mainlist" data-toggle="collapse" data-target="#teamscollapsed">
                <mat-icon svgIcon="team" class="material-icons"></mat-icon>
                <span class="optionNames"> Teams </span>
                <mat-icon style="line-height: 10px; margin-left: 23px;">
                    <i style="font-size: 15px;" class="fa fa-chevron-down" aria-hidden="false"></i>
                </mat-icon>
            </a>
            <div [style.marginLeft.px]="organizationHierarchy" id="teamscollapsed" class="collapse show">
                <a *ngIf="isTenantSelected" joyrideStep="teamusers" title="Users" matTooltipPosition="right"
                    text="SD-Wan gateway contains all the configuration options for the selected device"
                    routerLink="/teams/users" routerLinkActive="active" id="teams" style="max-height: 25px;"
                    class="mainlist">
                    <div class="d-inline-flex">
                        <mat-icon svgIcon="user" class="material-icons"></mat-icon><span class="optionNames">Users&nbsp;
                            &nbsp; </span>
                    </div>
                </a>
                <a *ngIf="!isTenantSelected" joyrideStep="teamusers" title="Users" matTooltipPosition="right"
                    text="SD-Wan gateway contains all the configuration options for the selected device"
                    (click)="showSelectTenant()" id="teams" style="max-height: 25px;" class="mainlist">
                    <div class="d-inline-flex">
                        <mat-icon svgIcon="user" class="material-icons"></mat-icon><span class="optionNames">Users&nbsp;
                            &nbsp; </span>
                    </div>
                </a>
                <a *ngIf="isTenantSelected" joyrideStep="group" title="Groups" matTooltipPosition="right"
                    text="Contains Configuration of Networks, Adding/Removing of devices from or to a network"
                    id="network" class="mainlist" routerLink="/teams/groups" routerLinkActive="active">
                    <mat-icon class="material-icons" svgIcon="teams"></mat-icon><span class="optionNames">Groups
                        &nbsp; &nbsp; </span>
                </a>
                <a *ngIf="!isTenantSelected" joyrideStep="group" title="Groups" matTooltipPosition="right"
                    text="Contains Configuration of Networks, Adding/Removing of devices from or to a network"
                    id="network" class="mainlist" (click)="showSelectTenant()">
                    <mat-icon class="material-icons" svgIcon="teams"></mat-icon><span class="optionNames">Groups
                        &nbsp; &nbsp; </span>
                </a>

            </div>
        </div>
        <div>
            <a joyrideStep="ndr" title="NDR" matTooltipPosition="right"
                text="Contains Configuration of Networks, Adding/Removing of devices from or to a network" id="ndr"
                class="mainlist" routerLink="/ndr" routerLinkActive="active">
                <mat-icon class="material-icons" svgIcon="ndr"></mat-icon><span class="optionNames">NDR</span>
            </a>
        </div>
        <div>
            <a joyrideStep="organization" title="Organization" text="Organization level Configuring and Monitoring"
                matTooltipPosition="right" class="mainlist" data-toggle="collapse" data-target="#orgcollapsed">
                <mat-icon svgIcon="organization" class="material-icons"></mat-icon>
                <span class="optionNames"> Organization </span>
                <mat-icon style="line-height: 10px; margin-left: 23px;">
                    <i style="font-size: 15px;" class="fa fa-chevron-down" aria-hidden="false"></i>
                </mat-icon>
            </a>
            <div [style.marginLeft.px]="organizationHierarchy" id="orgcollapsed" class="collapse show">
                <a joyrideStep="inventory" title="Inventory" text="Add/Edit/Remove devices and thier location"
                    id="inventory" matTooltipPosition="right" routerLink="/organization/inventory"
                    routerLinkActive="active" class="disable-select">
                    <mat-icon svgIcon="inventory" class="material-icons"></mat-icon><span
                        class="optionNames disable-select">Inventory</span>
                </a>
                <ng-container *ngIf="vpnDashboard; else sdwanstemplate">
                    <a joyrideStep="deviceSystem" title="Servers " matTooltipPosition="right" text="Creation of new Device
System for Server Mode and App Connector accessibility" routerLink="organization/device-system"
                        routerLinkActive="active" class="disable-select">
                        <mat-icon svgIcon="device" class="material-icons"></mat-icon><span class="optionNames"> Servers
                        </span>
                    </a>
                    <a joyrideStep="download" title="Download MicroZAccess" matTooltipPosition="right"
                        text="To download the MicroZacess Desktop App and its updates" routerLink="getmicrozaccess"
                        routerLinkActive="active" class="disable-select">
                        <mat-icon svgIcon="download" class="material-icons"></mat-icon>
                        <span class="optionNames">MZA Client</span>
                    </a>
                    <!-- <a joyrideStep="users" title="Users" matTooltipPosition="right"
                        text="Contains the list of registered users with their Configuring and Monitoring status"
                        routerLink="organization/systemtools/users" routerLinkActive="active" class="disable-select">
                        <mat-icon svgIcon="user" class="material-icons"></mat-icon><span class="optionNames"> Users
                        </span>
                    </a> -->
                    <a *ngIf="isTenantSelected" joyrideStep="webfilter" title="Web Filter" matTooltipPosition="right"
                        text="Contains the list of registered users with their Configuring and Monitoring status"
                        routerLink="organization/web-filter" routerLinkActive="active" class="disable-select">
                        <mat-icon svgIcon="web-filter" class="material-icons"></mat-icon><span class="optionNames"> Web
                            filter
                        </span>
                    </a>
                    <a *ngIf="!isTenantSelected" joyrideStep="webfilter" title="Web Filter" matTooltipPosition="right"
                        text="Contains the list of registered users with their Configuring and Monitoring status"
                        (click)="showSelectTenant()" class="disable-select">
                        <mat-icon svgIcon="web-filter" class="material-icons"></mat-icon><span class="optionNames"> Web
                            filter
                        </span>
                    </a>
                    <!-- <a *ngIf="!selectedDevice" joyrideStep="sysinfo" title="System Info" matTooltipPosition="right"
                        text="Contains the list of registered users with their Configuring and Monitoring status"
                        (click)="showDeviceNotSelected()" class="disable-select">
                        <mat-icon svgIcon="user" class="material-icons"></mat-icon><span class="optionNames"> Web filter
                        </span>
                    </a> -->

                    <!-- <a
                        joyrideStep="users"
                        title="Organization Monitor"
                        matTooltipPosition="right"
                        text="Organization level Monitoring of Interfaces, Alarms, BGP and IPSec VPN"
                        routerLink="organization/systemtools/teams"
                        routerLinkActive="active"
                        class="disable-select"
                    >
                        <mat-icon svgIcon="teams" class="material-icons"></mat-icon
                        ><span class="optionNames"> Teams </span>
                    </a> -->
                </ng-container>
                <ng-template #sdwanstemplate>
                    <a joyrideStep="monitor" title="Organization Monitor" matTooltipPosition="right"
                        text="Organization level Monitoring of Interfaces, Alarms, BGP and IPSec VPN"
                        routerLink="/organization/monitor" routerLinkActive="active" class="disable-select">
                        <mat-icon svgIcon="device" class="material-icons"></mat-icon><span class="optionNames"> Monitor
                        </span>
                    </a>

                    <a joyrideStep="securityGroup" title="Security Group" matTooltipPosition="right"
                        text="Creation of appropriate rights and permissions for specific groups."
                        routerLink="organization/overlay/security-group" routerLinkActive="active"
                        class="disable-select">
                        <mat-icon svgIcon="configure" class="material-icons"></mat-icon><span class="optionNames">
                            Configure </span>
                    </a>

                    <!-- <a joyrideStep="systemTools" title="System Tools" matTooltipPosition="right"
                        text="Configure/Remove/Edit Users, Notifications, Report "
                        routerLink="/organization/systemtools" routerLinkActive="active" class="disable-select">
                        <mat-icon svgIcon="systools" class="material-icons"></mat-icon><span class="optionNames"> System
                            tools </span>
                    </a> -->
                </ng-template>

                <a *ngIf="vpnDashboard" joyrideStep="securityGroup" title="Security Group" matTooltipPosition="right"
                    text="Creation of appropriate rights and permissions for specific groups."
                    routerLink="organization/overlay/security-group" routerLinkActive="active" class="disable-select">
                    <mat-icon svgIcon="configure" class="material-icons"></mat-icon><span class="optionNames"> Security
                        Groups </span>
                </a>
                <a *ngIf="vpnDashboard" joyrideStep="meshServer" title="MZA Overlay Servers" matTooltipPosition="right"
                    text="Creation of server by specifying the available group memeberships"
                    routerLink="organization/overlay/overlay-mesh-server" routerLinkActive="active"
                    class="disable-select">
                    <mat-icon svgIcon="overlayServer" class="material-icons"></mat-icon><span class="optionNames"> MZA
                        Overlay Servers</span>
                </a>
                <!-- <a routerLink="/device/configures" routerLinkActive="active">
            <mat-icon>devices</mat-icon>Devices</a>-->
                <a *ngIf="!vpnDashboard" joyrideStep="configTemplates" title="Config Templates"
                    matTooltipPosition="right" text="Add/Edit Config Templates, Apply Templates to devices "
                    id="configtemp" routerLink="/organization/configtemp" routerLinkActive="active"
                    class="disable-select">
                    <mat-icon svgIcon="templates" class="material-icons"></mat-icon><span class="optionNames">Config
                        Templates </span>
                </a>
            </div>
        </div>
        <div>
            <a *ngIf="isTenantSelected" joyrideStep="logs" title="Logs" matTooltipPosition="right"
                text="SD-Wan gateway contains all the configuration options for the selected device" routerLink="/logs"
                routerLinkActive="active" id="logs" style="max-height: 25px;" class="mainlist">
                <div class="d-inline-flex">
                    <mat-icon svgIcon="logs" class="material-icons"></mat-icon><span class="optionNames">Logs&nbsp;
                        &nbsp; </span>
                </div>
            </a>
            <a *ngIf="!isTenantSelected" joyrideStep="logs" title="Logs" matTooltipPosition="right"
                text="SD-Wan gateway contains all the configuration options for the selected device"
                (click)="showSelectTenant()" id="logs" style="max-height: 25px;" class="mainlist">
                <div class="d-inline-flex">
                    <mat-icon svgIcon="logs" class="material-icons"></mat-icon><span class="optionNames">Logs&nbsp;
                        &nbsp; </span>
                </div>
            </a>
        </div>

        <!--<div *ngIf="isSuperAdmin">
      <a class="mainlist" routerLink="/RUM" routerLinkActive="active">
        <mat-icon >list_alt</mat-icon><span class="optionNames">RUM</span>
      </a>
    </div>-->

        <div *ngIf="isSuperAdmin">
            <a class="mainlist" routerLink="/superAdmin" routerLinkActive="active">
                <mat-icon [matBadge]="newMessagesAdmin" matBadgeColor="warn" matBadgeSize="medium"
                    matTooltipPosition="right" [matBadgeHidden]="!newNotiffAdmin">stars</mat-icon><span
                    class="optionNames">Super Admin</span>
            </a>
        </div>

        <div class="end">
            <div>
                <a class="mainlist" matTooltipPosition="right" target="_blank" href="https://docs.cosgrid.com">
                    <mat-icon svgIcon="docs" class="material-icons"></mat-icon><span class="optionNames">Docs</span>
                </a>
            </div>
            <div>
                <a class="mainlist" matTooltipPosition="right" (click)="startTour()">
                    <mat-icon svgIcon="quickstart" class="material-icons"></mat-icon><span class="optionNames">Quick
                        Tour</span>
                </a>
            </div>
            <div *ngIf="!isSuperAdmin">
                <a class="mainlist" (click)="openLiveChat($event)" matTooltipPosition="right">
                    <mat-icon svgIcon="livechat" class="material-icons"></mat-icon><span class="optionNames">Live
                        Chat</span>
                </a>
            </div>
            <!-- <div>
                <a class="mainlist" matTooltipPosition="right" routerLink="/announcements" routerLinkActive="active">
                    <mat-icon><i style="font-size: 21px;" class="fas fa-bullhorn"></i></mat-icon
                    ><span class="optionNames">Announcements</span>
                </a>
            </div> -->

        </div>
    </div>
</mat-card>