import { NG_VALUE_ACCESSOR, NgControl, ControlValueAccessor, UntypedFormControl, Validators } from '@angular/forms';
import {
    Component,
    OnInit,
    forwardRef,
    Input,
    Injector,
    Optional,
    Self,
    AfterViewInit,
    SimpleChange,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { hasRequiredField } from '../../services/misc';

@Component({
    selector: 'config-input',
    templateUrl: './config-input.component.html',
    styleUrls: ['./config-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConfigInputComponent),
            multi: true,
        },
    ],
})
export class ConfigInputComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() name: string;
    @Input() className: string = '';
    @Input() placeholder: string = '';

    @Input() type: 'ip' | 'subnet' | 'any' | 'number' | 'string' = 'any';
    @Input() description;
    @Input() format;
    @Input() note;

    onChange: Function;
    onTouched: Function;

    inpType: string = 'text';

    input = new UntypedFormControl(['', [Validators.required]]);
    inputChangeSub: Subscription;

    currentFormControlName: any;

    isNgModel: boolean = false;
    isRequired: boolean = false;

    constructor(
        @Self()
        @Optional()
        private injector: Injector,
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.currentFormControlName = ngControl.name;
            this.input = ngControl.control as UntypedFormControl;
            if (hasRequiredField(this.input)) {
                this.isRequired = true;
            } else {
                this.isRequired = false;
            }
            this.input.updateValueAndValidity();
        } else {
            this.isNgModel = true;
            this.subscribeToValueChanges();
        }
        if (this.type == 'number')
            this.inpType = 'number'
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            let type = changes['type'].currentValue;
            let placeholder = changes['placeholder'].currentValue;
            if (placeholder != '') {
                if (type === 'ip') {
                    this.placeholder = '0.0.0.0';
                } else if (type === 'subnet') {
                    this.placeholder = '0.0.0.0/0';
                }
            }
        } catch (err) { }
    }
    subscribeToValueChanges() {
        this.inputChangeSub = this.input.valueChanges.subscribe((res) => {
            this.onChange(res);
        });
    }

    writeValue(value: string): void {
        if (value !== undefined) {
            this.input.setValue(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
