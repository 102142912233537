 <div class="modal-body">
  <form   [formGroup]="featureForm">
    <div class="search">
      <!-- <i style="font-size: 15px;position: relative;top: 1px;padding-left:3px;" class="fas fa-search mr-2"></i> -->
      <mat-form-field floatLabel="never" style="width:500px;padding-top: 7px">
        <input placeholder="Search" id="input" type="text" matInput 
        (keyup.enter)="onEnter()"
        (keyup.esc)="onEsc()"
        autocomplete="off"
        spellcheck="false"
        formControlName="featureGroup"
        [matAutocomplete]="autoGroup">
        <mat-autocomplete autoActiveFirstOption #autoGroup="matAutocomplete">
          <mat-optgroup *ngFor="let group of featureGroupOptions | async" [label]="group.group">
            <mat-option (click)="gotoOption()" *ngFor="let name of group.names,let i = index" [value]="name">
              <p style="font-size:15px"> {{name}} </p>
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form> 
</div>

