<label *ngIf="name" [class.required]="isRequired">
  {{name}}
  <!-- <Tooltip *ngIf="type == 'ip';else format" 
    [description]="description"
    [format]="'IP should be in the format \'x.x.x.x\' where x is a number from 0 - 255'"
    ></Tooltip>
    <ng-template #format>
        <Tooltip  
        [description]="description"
        ></Tooltip>
    </ng-template> -->

</label>
<input autocomplete="off" name="{{currentFormControlName}}" class="{{'form-control form-control-sm '+className}}"
  [ngClass]="{'is-invalid':input.invalid && (input.dirty || input.touched),
  'is-valid': isRequired && !input.invalid  && (input.dirty || input.touched)}" [type]="inpType" [formControl]="input"
  placeholder="{{placeholder}}">
<small *ngIf="input.invalid && (input.dirty || input.touched)">
  <mat-error>
    <ul>
      <li *ngIf="input['errors']['required']">Field is required</li>
      <li *ngIf="input['errors']['ippattern']">should be in the format 'x.x.x.x' </li>
    </ul>
  </mat-error>
</small>