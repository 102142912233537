import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cosgrid-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
