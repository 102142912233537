<label *ngIf="name" [class.required]="isRequired">
    {{ name }}
    <!-- <Tooltip [description]="description"></Tooltip> -->
</label>
<ng-container *ngIf="multiple == true">
    <!-- <select multiple (change)="optionChanged($event)" class="{{ 'form-control form-control-sm ' + className }}"
        [ngClass]="{
            'is-invalid': input.invalid && input.dirty,
            'is-valid': isRequired && !input.invalid && input.dirty
        }" type="text" [formControl]="input">
        <option *ngFor="let item of options" [value]="valueKey ? item[valueKey] : item">
            {{ viewKey ? item[viewKey] : item }}
        </option>
    </select>

    <small *ngIf="input.invalid && (input.dirty || input.touched)">
        <mat-error>
            <ul>
                <li *ngIf="input['errors']['required']">Field is required</li>
            </ul>
        </mat-error>
    </small> -->

    <mat-select class="{{ 'form-control form-control-sm ' + className }}" [ngClass]="{
        'is-invalid': input.invalid && input.dirty,
        'is-valid': isRequired && !input.invalid && input.dirty,
        'disabled': disabled
    }" type="text" [formControl]="input" multiple (selectionChange)="optionChanged($event)" [disabled]="disabled">
        <mat-option *ngIf="default" value="{{defaultValue}}">{{default}}</mat-option>
        <mat-option *ngFor="let item of options" [value]="valueKey ? item[valueKey] : item">
            <span *ngIf="dataWithIcon" [innerHTML]="viewKey?item[viewKey]:item"></span>
            <span *ngIf="!dataWithIcon">{{viewKey?item[viewKey]:item}}</span>
        </mat-option>

    </mat-select>
    <small *ngIf="input.invalid && (input.dirty || input.touched)">
        <mat-error>
            <ul>
                <li *ngIf="input['errors']['required']">Field is required</li>
            </ul>
        </mat-error>
    </small>
</ng-container>

<ng-container *ngIf="multiple == false">
    <mat-select (selectionChange)="optionChanged($event)" class="{{'form-control form-control-sm '+className}}"
        [ngClass]="{'is-invalid':input.invalid && (input.dirty),
  'is-valid': isRequired && !input.invalid  && (input.dirty)}" type="text" [formControl]="input">
        <mat-option *ngIf="default" value="{{defaultValue}}" [innerHTML]="default"></mat-option>
        <mat-option *ngFor="let item of options" [value]="valueKey?item[valueKey]:item">
            <span *ngIf="dataWithIcon" [innerHTML]="viewKey?item[viewKey]:item"></span>
            <span *ngIf="!dataWithIcon">{{viewKey?item[viewKey]:item}}</span>
        </mat-option>
    </mat-select>
    <small *ngIf="input.invalid && (input.dirty || input.touched)">
        <mat-error>
            <ul>
                <li *ngIf="input['errors']['required']">Field is required</li>
            </ul>
        </mat-error>
    </small>