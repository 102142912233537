<nav class="navbar navbar-expand-sm navbar-light ml-auto p-1">
    <a class="navbar-brand" (click)="gotoHome()">
        <img class="logo" alt="cosgrid" src="https://cdn.cosgrid.com/website/COSGridNet/assets/cosgrid-logo.png" />
    </a>
    <internet-tracker></internet-tracker>

    <div style="justify-content: flex-end;align-items: center;" class="collapse navbar-collapse"
        id="navbarNavAltMarkup">
        <div class="navbar-nav" style="align-items: center;">
            <!-- Search bar placeholder -->
            <div (click)="search()" class="searchbar" *ngIf="!vpnDashboard">
                <a joyrideStep="search" title="Search" text="Search for All featurers and Navigate to it">
                    <mat-icon>search</mat-icon>
                </a>
                <div id="searchFeatures" class="searchbox" style="padding-top: 6px;">Search</div>
            </div>
            <!-- End of Search bar placeholder -->

            <!-- select Tenant -->
            <mat-icon *ngIf="isSuperAdmin" style="margin-right: 3px;">group</mat-icon>
            <select-tenant *ngIf="isSuperAdmin"></select-tenant>
            <!-- End of select tenant -->

            <!-- select Network -->
            <select-network *ngIf="!vpnDashboard" joyrideStep="network" title="Device Groups"
                text="Select the required Device Groups, selecting a Device Group will filter the devices according to the groups "></select-network>
            <!-- End of select Network -->

            <mat-icon svgIcon="router" class="material-icons mr-1"></mat-icon>
            <a style="margin-right: 2px;" joyrideStep="device" title="Device"
                text="Select the required Device, selecting a Device will navigate to the Device details"
                class="devicedropdown nav-item nav-link py-0">
                <cosgrid-devices-list-dropdown-component (deviceSelected)="deviceSelected($event)">
                </cosgrid-devices-list-dropdown-component>
            </a>

            <a joyrideStep="notifications" title="Notifications" text="Notifications for Alarms"
                style="position: relative;margin-left: 5px;" [matMenuTriggerFor]="notificationMenu" class="iconWrapper"
                [ngClass]="{'selectedMenu': selectedButton.notification}">
                <mat-icon svgIcon="notification" style="width: 38px;"></mat-icon><span *ngIf="notifications.length > 0"
                    class="badge badge-info">{{ notifications.length }}</span>
            </a>

            <mat-menu class="notification-container" #notificationMenu="matMenu" [overlapTrigger]="false"
                yPosition="below" xPosition="before">
                <div mat-menu-item *ngFor="let item of notifications">
                    <p>
                        <i style="color: red;" *ngIf="item.status === 'Alerting'" class="fas fa-exclamation-circle"></i>
                        <i style="color: green;" *ngIf="item.status === 'OK'" class="fas fa-check-circle"></i>
                        {{ item.deviceName }} Has a {{ item.alarm }} Alarm
                    </p>
                </div>
                <div *ngIf="notifications.length > 0" (click)="dismissNotifications($event)" mat-menu-item>
                    <p style="text-align: center;"><i style="color: black;" class="fas fa-times"></i> Dismiss All</p>
                </div>
                <div *ngIf="notifications.length <= 0" mat-menu-item>
                    <p>You Have No Notifications</p>
                </div>
            </mat-menu>

            <a joyrideStep="support" title="Support" text="Support to raise a Ticket" style="position: relative;"
                class="iconWrapper" [matMenuTriggerFor]="support" [ngClass]="{'selectedMenu': selectedButton.support}">
                <mat-icon svgIcon="support" style="width:23px"></mat-icon>
            </a>

            <mat-menu class="notification-container iconWrapper" #support="matMenu" [overlapTrigger]="false"
                yPosition="below" xPosition="before">
                <button routerLink="/support/ticketRaise" mat-menu-item>
                    <mat-icon>record_voice_over</mat-icon>
                    <span>Raise a Ticket</span>
                </button>
                <button routerLink="/support/trackTicket" mat-menu-item>
                    <mat-icon>assignment</mat-icon>
                    <span>Track Status</span>
                </button>
                <button type="submit" onclick="window.open('/assets/downloadables/COSGrid_Remote_Diagnostic.deb')"
                    mat-menu-item>
                    <mat-icon>cloud_download</mat-icon>
                    <span>Download Diagnostic tool</span>
                </button>
            </mat-menu>
            <a class="iconWrapper" [routerLink]="['/settings']" [ngClass]="{'selectedMenu': selectedButton.setting}"
                style="margin-right: 5px;">
                <mat-icon svgIcon="setting"></mat-icon>
            </a>
            <a joyrideStep="profile" title="Profile" text="Edit your Profile Details"
                class="nav-item nav-link py-0 account-holder-container iconWrapper" [matMenuTriggerFor]="menu"
                style="margin-right: 1px !important;">
                <mat-icon svgIcon="profile"
                    style="height: 35px !important;width: 35px !important;margin-top: -5px;"></mat-icon>
                <div class="account-holder" style="color: #fff;">
                    <div class="user-info">
                        <div class="user-name">{{ user?.name }}</div>
                        <div class="user-company">{{ user?.companyName }}</div>
                    </div>
                </div>

                <span>
                    <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>account_circle</mat-icon>
          </button> -->
                    <mat-menu [overlapTrigger]="false" #menu="matMenu">

                        <button (click)="logout()" mat-menu-item routerLink=".">
                            <span><i class="icon-logout"></i>Logout</span>
                        </button>
                    </mat-menu>
                </span>


            </a>
        </div>
    </div>
</nav>