// AUTH
export const EMAIL: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ONLY_NUMBERS: RegExp = /^\d+$/;
export const ONLY_ALPHABETS: RegExp = /^[a-zA-Z]+$/;
export const ALLOW_ONLY_NUMBERS: RegExp = /^[0-9]*(?:\.\d{1,2})?$/;
export const PATTERN_FOR_IP_VERSION_4: RegExp = /\d{3,3}(?=\d{2,3})|\d+/g;
export const PATTERN_FOR_MAC_ADDRESS: RegExp = /\d{2}(?=\d{4})|\d+/g;

// IP Address
export const IPV4: RegExp = /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/;
export const IPV4Sub: RegExp = /^(([12]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])(\.|\/)){4}([1-2]?[0-9]|3[0-2])$/;

// PORT

export const PORT = /^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/;

// url

export const URL_REGEX: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

// config

export const NAME: RegExp = /^[a-zA-Z0-9_\.]+$/;
