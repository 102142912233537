import { hasRequiredField } from './../../services/misc/hasRequiredField';
import {
    Component,
    OnDestroy,
    AfterViewInit,
    Input,
    forwardRef,
    Injector,
    Self,
    Optional,
    SimpleChanges,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    NgControl,
    AbstractControl,
    Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidateIP } from 'app/shared/validators/ipValidator';
import { ValidatePort } from 'app/shared/validators/portValidator';
import { ValidateSubnet } from 'app/shared/validators/subnetValidator';
import { ValidateURL } from 'app/shared/validators/urlValidators';
import { ValidateEmail } from 'app/shared/validators/emailValidator';

@Component({
    selector: 'cosgrid-config-subnet',
    templateUrl: './config-subnet.component.html',
    styleUrls: ['./config-subnet.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConfigSubnetComponent),
            multi: true,
        },
    ],
})
export class ConfigSubnetComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @Input() className: string = '';
    @Input() name: string = '';
    @Input() type: 'ip' | 'port' | 'subnet' | 'ipsub' | 'url' | 'email' | 'text' = 'ip';

    @Input() placeholder = '';
    @Input() description = '';
    @Input() format;
    @Input() note = '';

    onChange: Function;
    onTouched: Function;

    valueChangeSub: Subscription;

    data = new UntypedFormControl('');

    isNgModel: boolean = false;
    isRequired: boolean = false;

    constructor(
        @Self()
        @Optional()
        private injector: Injector,
    ) {}

    ngAfterViewInit() {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.data = ngControl.control as UntypedFormControl;

            if (this.hasValidators(this.data)) {
                this.isRequired = true;
            } else {
                this.isRequired = false;
            }
            this.updateValidation();
        } else {
            this.subscribeToValueChanges();
            // Component is missing form control binding
        }
    }

    ngOnDestroy() {
        if (this.isNgModel) {
            this.valueChangeSub.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('type' in changes) {
            this.updateValidation();
        }
    }
    updateValidation() {
        if (this.type === 'ip') {
            if (this.data?.errors?.required) {
                this.data.setValidators([ValidateIP, Validators.required]);
            } else {
                this.data.setValidators([ValidateIP]);
            }
        } else if (this.type === 'port') {
            if (this.data?.errors?.required) {
                this.data.setValidators([ValidatePort, Validators.required]);
            } else {
                this.data.setValidators([ValidatePort]);
            }
        } else if (this.type === 'subnet') {
            if (this.data?.errors?.required) {
                this.data.setValidators([ValidateSubnet, Validators.required]);
            } else {
                this.data.setValidators([ValidateSubnet]);
            }
        } else if (this.type === 'url') {
            if (this.data?.errors?.required) {
                this.data.setValidators([ValidateURL, Validators.required]);
            } else {
                this.data.setValidators([ValidateURL]);
            }
        } else if (this.type === 'email') {
            if (this.data?.errors?.required) {
                this.data.setValidators([ValidateEmail, Validators.required]);
            } else {
                this.data.setValidators([ValidateEmail]);
            }
        }
    }

    subscribeToValueChanges() {
        this.valueChangeSub = this.data.valueChanges.subscribe((res) => {
            this.onChange(res);
        });
    }

    writeValue(value: string): void {
        if (value !== undefined) {
            this.data.setValue(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    hasValidators(control: AbstractControl): boolean {
        return !!control.validator;
    }
}
