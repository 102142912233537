
import { RouteConstants } from './../../configs/constants/route.constants';
import { Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SETTING_ACTION } from './../../store/actions/settings.action';
import { JoyrideService } from 'ngx-joyride';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DeviceList } from 'app/shared/model/device.list.model';
import { Subscription, Subject, Observable, filter } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { CommonService, WebsocketService, UserService, GlobalService } from 'app/shared/services/core';
import { CustomerListService, DeviceListService } from 'app/shared/services/comp';
import { GlobalConstants } from 'app/configs/constants';
import { AlertService } from 'app/shared/services/misc';
import { DashboardConfService } from 'app/modules/dashboard/services/dashboard-conf.service';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { Network } from 'app/shared/model/network';
import { SuperAdminListService } from 'app/store/services/super-admin-list.service';
import { ITile } from 'app/modules/dashboard/components/tile-group/tile-group.component';
import { ITenantSettings } from 'app/shared/model/settings.model';
import { lastValueFrom } from 'rxjs';
import { AuthenticationService } from 'app/modules/auth/services/authentication.service';
@Component({
    selector: 'cosgrid-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
    interfaces;
    selectedinterface;

    interval;
    errCount = 0;
    tenantinfo = {
        devices: ' - ',
        links: ' - ',
        online_devices: ' - ',
        active_links: ' - ',
        inactive_links: ' - ',
        total_networks: ' - ',
        deployed_devices: ' - ',
        offline_devices: '-',
        users: '-',
        online_users: '-',
    };
    showGraph = false;
    currentTenantId: string = this.customerListService.getTenantId() || '';

    currentTenantName: string = this.customerListService.getTenantName();

    selectedNetwork: Network = {
        networkName: '',
    };

    tiles = [];

    routeLinks: any[];
    activeLinkIndex = 0;

    previousDeviceList: DeviceList[] = [];
    devices: DeviceList[] = [];
    claimedDevices: DeviceList[] = [];
    sub: any;

    networkSubscription: Subscription;
    deviceListSubscription: Subscription;

    immedieateFallbackSubscription: Subscription;
    wsDataStreamSubscription: Subscription;

    count = 0;
    dashbaordConf: any;

    id: any;
    statusLoaded = false;

    deviceList = [];
    pollRef: any;

    constructor(
        private http: HttpClient,
        private customerListService: CustomerListService,
        private deviceListService: DeviceListService,
        private globalConstants: GlobalConstants,
        private alert: AlertService,
        private router: Router,
        private commonservice: CommonService,
        private wsService: WebsocketService,
        private dashboard: DashboardConfService,
        private store: Store<CosgridAppStore>,
        private superAdminListService: SuperAdminListService,
        private globalService: GlobalService,
        private social: SocialAuthService,
        private authservice: AuthenticationService,
    ) {

        // if (!this.globalService.isSuperAdmin()) {
        //     this.getDashboardConfig();
        // } else if (this.customerListService.getTenantId()) {
        //     console.log("inside tenant id");

        //     this.getDashboardConfig();
        // }
        this.getSettingDashboardValue();
    }

    async ngOnInit() {
        console.log("init start");
        try {
            // TODO: find a proper solution for triggering networks http call

            this.superAdminListService.dispatchIsTenantSelectedToStore(false); // dispatch will only trigger if the value changes, so making it false and then true
            this.superAdminListService.dispatchIsTenantSelectedToStore(true);
            this.deviceListService.dispatchSelectedDeviceToStore({}); // to make the network dropdown visible and initiate the getNetwork http call
            await this.getDashboardConfig();
            this.subscribeToSelectedNetwork();
            this.getDataFromDataEmitter(); // to get data from data emitter
            this.getALLInfoWs();
            this.subscribeToDevicesSubject();
            this.polling();
        } catch (err) { }
    }

    getDashboardConfig() {
        return new Promise(async (resolve, reject) => {
            try {
                let data: any = await this.dashboard.getDashboardConf().toPromise();
                data.dashboard.devReg_autoApprove = data.devReg_autoApprove

                this.store.dispatch({
                    type: SETTING_ACTION,
                    payload: data.dashboard,
                });
                resolve('');
            } catch (error) {
                reject();
            }
        });
    }

    getSettingDashboardValue() {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.dashbaordConf = value.setting;
            });

        this.showGraph = this.dashbaordConf?.netflow && !this.dashbaordConf?.vpnDashboard;
    }

    polling() {
        this.interval = setInterval(() => {
            // polling for tenant info
            this.getALLInfoWs();
        }, 10000);
        // }, this.globalConstants.DASHBOARD_POLLING_TIME)
    }

    getDataFromDataEmitter() {
        if (!this.wsDataStreamSubscription) {
            this.subscribeToWsDataStream();
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval);
        try {
            this.networkSubscription.unsubscribe();
            this.deviceListSubscription.unsubscribe();
            this.wsDataStreamSubscription.unsubscribe(); // unscubscring from the data emitter so it wont terminate the webscoket
        } catch (err) { }
    }

    subscribeToSelectedNetwork() {
        this.networkSubscription = this.store
            .select((slices) => slices.networkSlice.selectedNetwork)
            .subscribe((res) => {
                if (res.id != this.selectedNetwork.id) {
                    this.selectedNetwork = res;
                    this.getALLInfoWs();
                }
            });
    }
    async getALLInfoWs() {
        try {
            // await this.getDashboardConfig();
            this.getSettingDashboardValue();
            this.wsService.postDataInWs({
                tenant_id: this.customerListService.getTenantId() || '',
                network_id: this.selectedNetwork.id,
                type: this.dashbaordConf?.vpnDashboard ? 'endpoint' : 'device',
            });
            let type = this.dashbaordConf?.vpnDashboard ? 'endpoint' : 'device';
        } catch (error) {
            this.alert.snack('Error occured in settings', 'OK');
            // setTimeout(() => {
            //     this.authservice.logout();
            // }, 2000);
        }
    }

    subscribeToWsDataStream() {
        this.wsDataStreamSubscription = this.wsService.getDataEmitter().subscribe(
            (res) => {
                if (res.tenantinfo) {
                    this.tenantinfo = res.tenantinfo;
                    this.tiles = this.getTileInfo();
                }
            },
            (err) => { },
            () => { },
        );
    }

    getAccountInfo() {
        this.http
            .post(this.globalConstants.STATUS_TENANT_INFO, {
                tenantname: this.currentTenantName,
                network_id: this.selectedNetwork,
            })
            .subscribe(
                (res: any) => {
                    const body = res;
                    this.tenantinfo = body;
                },
                (err) => {
                    this.errCount = this.errCount + 1;

                    if (this.errCount > 2) {
                        this.errCount = 0;
                        clearInterval(this.interval);

                        this.pollRef = this.alert.pollingRestart.subscribe(() => {
                            this.getAccountInfo();
                            this.polling();
                        });
                    }
                },
            );
    }

    subscribeToDevicesSubject() {
        this.deviceListSubscription = this.deviceListService.deviceListSubject.subscribe((res) => {
            this.previousDeviceList = [...this.deviceList];
            this.deviceList = res;
        });
    }

    getTileInfo(): ITile[] {
        if (this.dashbaordConf?.vpnDashboard) {
            return [
                {
                    name: 'Total Devices',
                    value: this.tenantinfo.devices,
                    color: '#0078BE',
                    icon: 'router',
                },
                {
                    name: 'Online Devices',
                    value: this.tenantinfo.online_devices,
                    color: '#298C00',
                    icon: 'router',
                },

                {
                    name: 'Total Users',
                    value: this.tenantinfo.users,
                    color: '#0078BE',
                    icon: 'router',
                },

                {
                    name: 'Online Users',
                    value: this.tenantinfo.online_users,
                    color: '#298C00',
                    icon: 'router',
                },
            ];
        } else {
            return [
                {
                    name: 'Total Devices',
                    value: this.tenantinfo.devices,
                    color: '#0078BE',
                    icon: 'router',
                },
                {
                    name: 'Deployed Devices',
                    value: this.tenantinfo.deployed_devices,
                    color: '#BB64FF',
                    icon: 'router',
                },
                {
                    name: 'Online Devices',
                    value: this.tenantinfo.online_devices,
                    color: '#298C00',
                    icon: 'router',
                },

                {
                    name: 'Total Links',
                    value: this.tenantinfo.links,
                    color: '#0078BE',
                    icon: 'router',
                },

                {
                    name: 'Active Links',
                    value: this.tenantinfo.active_links,
                    color: '#298C00',
                    icon: 'router',
                },
            ];
        }
    }
}
