import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { StatusService } from 'app/modules/sd-wan-gateway/sub-modules/status/services/status.service';

@Component({
    selector: 'cosgrid-popover-graph',
    templateUrl: './popover-graph.component.html',
    styleUrls: ['./popover-graph.component.scss'],
})
export class PopoverGraphComponent implements OnInit {
    panel1;
    panel2;
    panel3;
    panel1Data;
    panel2Data;
    panel3Data;

    pingGraphHeight = 230;
    pingGraphWidth = 260;

    interfaceGraphHeight = 230;
    interfaceGraphWidth = 530;
    isPanel1n2Available: boolean = true;
    isPanel3Available: boolean = true;
    deviceid = '';
    grafanaIP: string = 'https://cosgridnetworks.com/grafana/v2/';
    selectedInterface = '';
    dashboardId;
    from: number = new Date().getTime() - 600000;
    to: number = new Date().getTime();

    constructor(
        private sanitizer: DomSanitizer,
        private statusService: StatusService,
        private dialogRef: MatDialogRef<PopoverGraphComponent>,

        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.deviceid = data.item.id;
        this.selectedInterface = data.iface;
        try {
            this.panel1Data = this.data.item.ping_panels[this.selectedInterface][0];
            this.panel2Data = this.data.item.ping_panels[this.selectedInterface][1];
        } catch (err) {
            this.isPanel1n2Available = false;
        }
        try {
            this.panel3Data = this.data.item.iface_panels[this.selectedInterface][0];
        } catch (err) {
            this.isPanel3Available = false;
        }
        this.panel1 = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.grafanaIP}d-solo/c2f71c39-47dd-4d60-a28f-4532244ddadd/${this.deviceid}-ping?orgId=1&from=${this.from}&to=${this.to}&refresh=5s&panelId=${this.panel1Data}&theme=dark" width="${this.pingGraphWidth}" height="${this.pingGraphHeight}" frameborder="0"`,
        );
        this.panel2 = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.grafanaIP}d-solo/c2f71c39-47dd-4d60-a28f-4532244ddadd/${this.deviceid}-ping?orgId=1&from=${this.from}&to=${this.to}&refresh=5s&panelId=${this.panel2Data}&theme=dark" width="${this.pingGraphWidth}" height="${this.pingGraphHeight}" frameborder="0"`,
        );
        this.panel3 = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.grafanaIP}d-solo/c2f71c39-47dd-4d60-a28f-4532244ddadd/${this.deviceid}-interface?orgId=1&from=${this.from}&to=${this.to}&refresh=5s&panelId=${this.panel3Data}&theme=dark" width="${this.interfaceGraphWidth}" height="${this.interfaceGraphHeight}" frameborder="0"`,
        );
    }
    ngOnInit() {}
}
