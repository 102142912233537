import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryDataService {

  countryCode = [
    {
      "name": "Afghanistan",
      "code": "AF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-af\"></span><span>Afghanistan</span>"
    },
    {
      "name": "Åland Islands",
      "code": "AX",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ax\"></span><span>Åland Islands</span>"
    },
    {
      "name": "Albania",
      "code": "AL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-al\"></span><span>Albania</span>"
    },
    {
      "name": "Algeria",
      "code": "DZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-dz\"></span><span>Algeria</span>"
    },
    {
      "name": "American Samoa",
      "code": "AS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-as\"></span><span>American Samoa</span>"
    },
    {
      "name": "AndorrA",
      "code": "AD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ad\"></span><span>AndorrA</span>"
    },
    {
      "name": "Angola",
      "code": "AO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ao\"></span><span>Angola</span>"
    },
    {
      "name": "Anguilla",
      "code": "AI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ai\"></span><span>Anguilla</span>"
    },
    {
      "name": "Antarctica",
      "code": "AQ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-aq\"></span><span>Antarctica</span>"
    },
    {
      "name": "Antigua and Barbuda",
      "code": "AG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ag\"></span><span>Antigua and Barbuda</span>"
    },
    {
      "name": "Argentina",
      "code": "AR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ar\"></span><span>Argentina</span>"
    },
    {
      "name": "Armenia",
      "code": "AM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-am\"></span><span>Armenia</span>"
    },
    {
      "name": "Aruba",
      "code": "AW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-aw\"></span><span>Aruba</span>"
    },
    {
      "name": "Australia",
      "code": "AU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-au\"></span><span>Australia</span>"
    },
    {
      "name": "Austria",
      "code": "AT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-at\"></span><span>Austria</span>"
    },
    {
      "name": "Azerbaijan",
      "code": "AZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-az\"></span><span>Azerbaijan</span>"
    },
    {
      "name": "Bahamas",
      "code": "BS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bs\"></span><span>Bahamas</span>"
    },
    {
      "name": "Bahrain",
      "code": "BH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bh\"></span><span>Bahrain</span>"
    },
    {
      "name": "Bangladesh",
      "code": "BD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bd\"></span><span>Bangladesh</span>"
    },
    {
      "name": "Barbados",
      "code": "BB",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bb\"></span><span>Barbados</span>"
    },
    {
      "name": "Belarus",
      "code": "BY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-by\"></span><span>Belarus</span>"
    },
    {
      "name": "Belgium",
      "code": "BE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-be\"></span><span>Belgium</span>"
    },
    {
      "name": "Belize",
      "code": "BZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bz\"></span><span>Belize</span>"
    },
    {
      "name": "Benin",
      "code": "BJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bj\"></span><span>Benin</span>"
    },
    {
      "name": "Bermuda",
      "code": "BM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bm\"></span><span>Bermuda</span>"
    },
    {
      "name": "Bhutan",
      "code": "BT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bt\"></span><span>Bhutan</span>"
    },
    {
      "name": "Bolivia",
      "code": "BO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bo\"></span><span>Bolivia</span>"
    },
    {
      "name": "Bosnia and Herzegovina",
      "code": "BA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ba\"></span><span>Bosnia and Herzegovina</span>"
    },
    {
      "name": "Botswana",
      "code": "BW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bw\"></span><span>Botswana</span>"
    },
    {
      "name": "Bouvet Island",
      "code": "BV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bv\"></span><span>Bouvet Island</span>"
    },
    {
      "name": "Brazil",
      "code": "BR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-br\"></span><span>Brazil</span>"
    },
    {
      "name": "British Indian Ocean Territory",
      "code": "IO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-io\"></span><span>British Indian Ocean Territory</span>"
    },
    {
      "name": "Brunei Darussalam",
      "code": "BN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bn\"></span><span>Brunei Darussalam</span>"
    },
    {
      "name": "Bulgaria",
      "code": "BG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bg\"></span><span>Bulgaria</span>"
    },
    {
      "name": "Burkina Faso",
      "code": "BF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bf\"></span><span>Burkina Faso</span>"
    },
    {
      "name": "Burundi",
      "code": "BI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-bi\"></span><span>Burundi</span>"
    },
    {
      "name": "Cambodia",
      "code": "KH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kh\"></span><span>Cambodia</span>"
    },
    {
      "name": "Cameroon",
      "code": "CM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cm\"></span><span>Cameroon</span>"
    },
    {
      "name": "Canada",
      "code": "CA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ca\"></span><span>Canada</span>"
    },
    {
      "name": "Cape Verde",
      "code": "CV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cv\"></span><span>Cape Verde</span>"
    },
    {
      "name": "Cayman Islands",
      "code": "KY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ky\"></span><span>Cayman Islands</span>"
    },
    {
      "name": "Central African Republic",
      "code": "CF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cf\"></span><span>Central African Republic</span>"
    },
    {
      "name": "Chad",
      "code": "TD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-td\"></span><span>Chad</span>"
    },
    {
      "name": "Chile",
      "code": "CL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cl\"></span><span>Chile</span>"
    },
    {
      "name": "China",
      "code": "CN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cn\"></span><span>China</span>"
    },
    {
      "name": "Christmas Island",
      "code": "CX",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cx\"></span><span>Christmas Island</span>"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "code": "CC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cc\"></span><span>Cocos (Keeling) Islands</span>"
    },
    {
      "name": "Colombia",
      "code": "CO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-co\"></span><span>Colombia</span>"
    },
    {
      "name": "Comoros",
      "code": "KM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-km\"></span><span>Comoros</span>"
    },
    {
      "name": "Congo",
      "code": "CG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cg\"></span><span>Congo</span>"
    },
    {
      "name": "Congo, The Democratic Republic of the",
      "code": "CD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cd\"></span><span>Congo, The Democratic Republic of the</span>"
    },
    {
      "name": "Cook Islands",
      "code": "CK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ck\"></span><span>Cook Islands</span>"
    },
    {
      "name": "Costa Rica",
      "code": "CR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cr\"></span><span>Costa Rica</span>"
    },
    {
      "name": "Cote D'Ivoire",
      "code": "CI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ci\"></span><span>Cote D'Ivoire</span>"
    },
    {
      "name": "Croatia",
      "code": "HR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hr\"></span><span>Croatia</span>"
    },
    {
      "name": "Cuba",
      "code": "CU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cu\"></span><span>Cuba</span>"
    },
    {
      "name": "Cyprus",
      "code": "CY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cy\"></span><span>Cyprus</span>"
    },
    {
      "name": "Czech Republic",
      "code": "CZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cz\"></span><span>Czech Republic</span>"
    },
    {
      "name": "Denmark",
      "code": "DK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-dk\"></span><span>Denmark</span>"
    },
    {
      "name": "Djibouti",
      "code": "DJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-dj\"></span><span>Djibouti</span>"
    },
    {
      "name": "Dominica",
      "code": "DM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-dm\"></span><span>Dominica</span>"
    },
    {
      "name": "Dominican Republic",
      "code": "DO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-do\"></span><span>Dominican Republic</span>"
    },
    {
      "name": "Ecuador",
      "code": "EC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ec\"></span><span>Ecuador</span>"
    },
    {
      "name": "Egypt",
      "code": "EG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-eg\"></span><span>Egypt</span>"
    },
    {
      "name": "El Salvador",
      "code": "SV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sv\"></span><span>El Salvador</span>"
    },
    {
      "name": "Equatorial Guinea",
      "code": "GQ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gq\"></span><span>Equatorial Guinea</span>"
    },
    {
      "name": "Eritrea",
      "code": "ER",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-er\"></span><span>Eritrea</span>"
    },
    {
      "name": "Estonia",
      "code": "EE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ee\"></span><span>Estonia</span>"
    },
    {
      "name": "Ethiopia",
      "code": "ET",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-et\"></span><span>Ethiopia</span>"
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "code": "FK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fk\"></span><span>Falkland Islands (Malvinas)</span>"
    },
    {
      "name": "Faroe Islands",
      "code": "FO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fo\"></span><span>Faroe Islands</span>"
    },
    {
      "name": "Fiji",
      "code": "FJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fj\"></span><span>Fiji</span>"
    },
    {
      "name": "Finland",
      "code": "FI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fi\"></span><span>Finland</span>"
    },
    {
      "name": "France",
      "code": "FR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fr\"></span><span>France</span>"
    },
    {
      "name": "French Guiana",
      "code": "GF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gf\"></span><span>French Guiana</span>"
    },
    {
      "name": "French Polynesia",
      "code": "PF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pf\"></span><span>French Polynesia</span>"
    },
    {
      "name": "French Southern Territories",
      "code": "TF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tf\"></span><span>French Southern Territories</span>"
    },
    {
      "name": "Gabon",
      "code": "GA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ga\"></span><span>Gabon</span>"
    },
    {
      "name": "Gambia",
      "code": "GM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gm\"></span><span>Gambia</span>"
    },
    {
      "name": "Georgia",
      "code": "GE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ge\"></span><span>Georgia</span>"
    },
    {
      "name": "Germany",
      "code": "DE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-de\"></span><span>Germany</span>"
    },
    {
      "name": "Ghana",
      "code": "GH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gh\"></span><span>Ghana</span>"
    },
    {
      "name": "Gibraltar",
      "code": "GI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gi\"></span><span>Gibraltar</span>"
    },
    {
      "name": "Greece",
      "code": "GR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gr\"></span><span>Greece</span>"
    },
    {
      "name": "Greenland",
      "code": "GL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gl\"></span><span>Greenland</span>"
    },
    {
      "name": "Grenada",
      "code": "GD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gd\"></span><span>Grenada</span>"
    },
    {
      "name": "Guadeloupe",
      "code": "GP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gp\"></span><span>Guadeloupe</span>"
    },
    {
      "name": "Guam",
      "code": "GU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gu\"></span><span>Guam</span>"
    },
    {
      "name": "Guatemala",
      "code": "GT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gt\"></span><span>Guatemala</span>"
    },
    {
      "name": "Guernsey",
      "code": "GG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gg\"></span><span>Guernsey</span>"
    },
    {
      "name": "Guinea",
      "code": "GN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gn\"></span><span>Guinea</span>"
    },
    {
      "name": "Guinea-Bissau",
      "code": "GW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gw\"></span><span>Guinea-Bissau</span>"
    },
    {
      "name": "Guyana",
      "code": "GY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gy\"></span><span>Guyana</span>"
    },
    {
      "name": "Haiti",
      "code": "HT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ht\"></span><span>Haiti</span>"
    },
    {
      "name": "Heard Island and Mcdonald Islands",
      "code": "HM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hm\"></span><span>Heard Island and Mcdonald Islands</span>"
    },
    {
      "name": "Holy See (Vatican City State)",
      "code": "VA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-va\"></span><span>Holy See (Vatican City State)</span>"
    },
    {
      "name": "Honduras",
      "code": "HN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hn\"></span><span>Honduras</span>"
    },
    {
      "name": "Hong Kong",
      "code": "HK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hk\"></span><span>Hong Kong</span>"
    },
    {
      "name": "Hungary",
      "code": "HU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-hu\"></span><span>Hungary</span>"
    },
    {
      "name": "Iceland",
      "code": "IS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-is\"></span><span>Iceland</span>"
    },
    {
      "name": "India",
      "code": "IN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-in\"></span><span>India</span>"
    },
    {
      "name": "Indonesia",
      "code": "ID",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-id\"></span><span>Indonesia</span>"
    },
    {
      "name": "Iran, Islamic Republic Of",
      "code": "IR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ir\"></span><span>Iran, Islamic Republic Of</span>"
    },
    {
      "name": "Iraq",
      "code": "IQ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-iq\"></span><span>Iraq</span>"
    },
    {
      "name": "Ireland",
      "code": "IE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ie\"></span><span>Ireland</span>"
    },
    {
      "name": "Isle of Man",
      "code": "IM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-im\"></span><span>Isle of Man</span>"
    },
    {
      "name": "Israel",
      "code": "IL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-il\"></span><span>Israel</span>"
    },
    {
      "name": "Italy",
      "code": "IT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-it\"></span><span>Italy</span>"
    },
    {
      "name": "Jamaica",
      "code": "JM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-jm\"></span><span>Jamaica</span>"
    },
    {
      "name": "Japan",
      "code": "JP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-jp\"></span><span>Japan</span>"
    },
    {
      "name": "Jersey",
      "code": "JE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-je\"></span><span>Jersey</span>"
    },
    {
      "name": "Jordan",
      "code": "JO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-jo\"></span><span>Jordan</span>"
    },
    {
      "name": "Kazakhstan",
      "code": "KZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kz\"></span><span>Kazakhstan</span>"
    },
    {
      "name": "Kenya",
      "code": "KE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ke\"></span><span>Kenya</span>"
    },
    {
      "name": "Kiribati",
      "code": "KI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ki\"></span><span>Kiribati</span>"
    },
    {
      "name": "Korea, Democratic People'S Republic of",
      "code": "KP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kp\"></span><span>Korea, Democratic People'S Republic of</span>"
    },
    {
      "name": "Korea, Republic of",
      "code": "KR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kr\"></span><span>Korea, Republic of</span>"
    },
    {
      "name": "Kuwait",
      "code": "KW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kw\"></span><span>Kuwait</span>"
    },
    {
      "name": "Kyrgyzstan",
      "code": "KG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kg\"></span><span>Kyrgyzstan</span>"
    },
    {
      "name": "Lao People'S Democratic Republic",
      "code": "LA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-la\"></span><span>Lao People'S Democratic Republic</span>"
    },
    {
      "name": "Latvia",
      "code": "LV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lv\"></span><span>Latvia</span>"
    },
    {
      "name": "Lebanon",
      "code": "LB",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lb\"></span><span>Lebanon</span>"
    },
    {
      "name": "Lesotho",
      "code": "LS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ls\"></span><span>Lesotho</span>"
    },
    {
      "name": "Liberia",
      "code": "LR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lr\"></span><span>Liberia</span>"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "code": "LY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ly\"></span><span>Libyan Arab Jamahiriya</span>"
    },
    {
      "name": "Liechtenstein",
      "code": "LI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-li\"></span><span>Liechtenstein</span>"
    },
    {
      "name": "Lithuania",
      "code": "LT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lt\"></span><span>Lithuania</span>"
    },
    {
      "name": "Luxembourg",
      "code": "LU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lu\"></span><span>Luxembourg</span>"
    },
    {
      "name": "Macao",
      "code": "MO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mo\"></span><span>Macao</span>"
    },
    {
      "name": "Macedonia, The Former Yugoslav Republic of",
      "code": "MK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mk\"></span><span>Macedonia, The Former Yugoslav Republic of</span>"
    },
    {
      "name": "Madagascar",
      "code": "MG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mg\"></span><span>Madagascar</span>"
    },
    {
      "name": "Malawi",
      "code": "MW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mw\"></span><span>Malawi</span>"
    },
    {
      "name": "Malaysia",
      "code": "MY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-my\"></span><span>Malaysia</span>"
    },
    {
      "name": "Maldives",
      "code": "MV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mv\"></span><span>Maldives</span>"
    },
    {
      "name": "Mali",
      "code": "ML",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ml\"></span><span>Mali</span>"
    },
    {
      "name": "Malta",
      "code": "MT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mt\"></span><span>Malta</span>"
    },
    {
      "name": "Marshall Islands",
      "code": "MH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mh\"></span><span>Marshall Islands</span>"
    },
    {
      "name": "Martinique",
      "code": "MQ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mq\"></span><span>Martinique</span>"
    },
    {
      "name": "Mauritania",
      "code": "MR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mr\"></span><span>Mauritania</span>"
    },
    {
      "name": "Mauritius",
      "code": "MU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mu\"></span><span>Mauritius</span>"
    },
    {
      "name": "Mayotte",
      "code": "YT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-yt\"></span><span>Mayotte</span>"
    },
    {
      "name": "Mexico",
      "code": "MX",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mx\"></span><span>Mexico</span>"
    },
    {
      "name": "Micronesia, Federated States of",
      "code": "FM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-fm\"></span><span>Micronesia, Federated States of</span>"
    },
    {
      "name": "Moldova, Republic of",
      "code": "MD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-md\"></span><span>Moldova, Republic of</span>"
    },
    {
      "name": "Monaco",
      "code": "MC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mc\"></span><span>Monaco</span>"
    },
    {
      "name": "Mongolia",
      "code": "MN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mn\"></span><span>Mongolia</span>"
    },
    {
      "name": "Montserrat",
      "code": "MS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ms\"></span><span>Montserrat</span>"
    },
    {
      "name": "Morocco",
      "code": "MA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ma\"></span><span>Morocco</span>"
    },
    {
      "name": "Mozambique",
      "code": "MZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mz\"></span><span>Mozambique</span>"
    },
    {
      "name": "Myanmar",
      "code": "MM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mm\"></span><span>Myanmar</span>"
    },
    {
      "name": "Namibia",
      "code": "NA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-na\"></span><span>Namibia</span>"
    },
    {
      "name": "Nauru",
      "code": "NR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nr\"></span><span>Nauru</span>"
    },
    {
      "name": "Nepal",
      "code": "NP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-np\"></span><span>Nepal</span>"
    },
    {
      "name": "Netherlands",
      "code": "NL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nl\"></span><span>Netherlands</span>"
    },
    {
      "name": "Netherlands Antilles",
      "code": "AN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-an\"></span><span>Netherlands Antilles</span>"
    },
    {
      "name": "New Caledonia",
      "code": "NC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nc\"></span><span>New Caledonia</span>"
    },
    {
      "name": "New Zealand",
      "code": "NZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nz\"></span><span>New Zealand</span>"
    },
    {
      "name": "Nicaragua",
      "code": "NI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ni\"></span><span>Nicaragua</span>"
    },
    {
      "name": "Niger",
      "code": "NE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ne\"></span><span>Niger</span>"
    },
    {
      "name": "Nigeria",
      "code": "NG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ng\"></span><span>Nigeria</span>"
    },
    {
      "name": "Niue",
      "code": "NU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nu\"></span><span>Niue</span>"
    },
    {
      "name": "Norfolk Island",
      "code": "NF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-nf\"></span><span>Norfolk Island</span>"
    },
    {
      "name": "Northern Mariana Islands",
      "code": "MP",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-mp\"></span><span>Northern Mariana Islands</span>"
    },
    {
      "name": "Norway",
      "code": "NO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-no\"></span><span>Norway</span>"
    },
    {
      "name": "Oman",
      "code": "OM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-om\"></span><span>Oman</span>"
    },
    {
      "name": "Pakistan",
      "code": "PK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pk\"></span><span>Pakistan</span>"
    },
    {
      "name": "Palau",
      "code": "PW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pw\"></span><span>Palau</span>"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "code": "PS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ps\"></span><span>Palestinian Territory, Occupied</span>"
    },
    {
      "name": "Panama",
      "code": "PA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pa\"></span><span>Panama</span>"
    },
    {
      "name": "Papua New Guinea",
      "code": "PG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pg\"></span><span>Papua New Guinea</span>"
    },
    {
      "name": "Paraguay",
      "code": "PY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-py\"></span><span>Paraguay</span>"
    },
    {
      "name": "Peru",
      "code": "PE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pe\"></span><span>Peru</span>"
    },
    {
      "name": "Philippines",
      "code": "PH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ph\"></span><span>Philippines</span>"
    },
    {
      "name": "Pitcairn",
      "code": "PN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pn\"></span><span>Pitcairn</span>"
    },
    {
      "name": "Poland",
      "code": "PL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pl\"></span><span>Poland</span>"
    },
    {
      "name": "Portugal",
      "code": "PT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pt\"></span><span>Portugal</span>"
    },
    {
      "name": "Puerto Rico",
      "code": "PR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pr\"></span><span>Puerto Rico</span>"
    },
    {
      "name": "Qatar",
      "code": "QA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-qa\"></span><span>Qatar</span>"
    },
    {
      "name": "Reunion",
      "code": "RE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-re\"></span><span>Reunion</span>"
    },
    {
      "name": "Romania",
      "code": "RO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ro\"></span><span>Romania</span>"
    },
    {
      "name": "Russian Federation",
      "code": "RU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ru\"></span><span>Russian Federation</span>"
    },
    {
      "name": "RWANDA",
      "code": "RW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-rw\"></span><span>RWANDA</span>"
    },
    {
      "name": "Saint Helena",
      "code": "SH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sh\"></span><span>Saint Helena</span>"
    },
    {
      "name": "Saint Kitts and Nevis",
      "code": "KN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-kn\"></span><span>Saint Kitts and Nevis</span>"
    },
    {
      "name": "Saint Lucia",
      "code": "LC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lc\"></span><span>Saint Lucia</span>"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "code": "PM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-pm\"></span><span>Saint Pierre and Miquelon</span>"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "code": "VC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vc\"></span><span>Saint Vincent and the Grenadines</span>"
    },
    {
      "name": "Samoa",
      "code": "WS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ws\"></span><span>Samoa</span>"
    },
    {
      "name": "San Marino",
      "code": "SM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sm\"></span><span>San Marino</span>"
    },
    {
      "name": "Sao Tome and Principe",
      "code": "ST",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-st\"></span><span>Sao Tome and Principe</span>"
    },
    {
      "name": "Saudi Arabia",
      "code": "SA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sa\"></span><span>Saudi Arabia</span>"
    },
    {
      "name": "Senegal",
      "code": "SN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sn\"></span><span>Senegal</span>"
    },
    {
      "name": "Serbia and Montenegro",
      "code": "CS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-cs\"></span><span>Serbia and Montenegro</span>"
    },
    {
      "name": "Seychelles",
      "code": "SC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sc\"></span><span>Seychelles</span>"
    },
    {
      "name": "Sierra Leone",
      "code": "SL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sl\"></span><span>Sierra Leone</span>"
    },
    {
      "name": "Singapore",
      "code": "SG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sg\"></span><span>Singapore</span>"
    },
    {
      "name": "Slovakia",
      "code": "SK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sk\"></span><span>Slovakia</span>"
    },
    {
      "name": "Slovenia",
      "code": "SI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-si\"></span><span>Slovenia</span>"
    },
    {
      "name": "Solomon Islands",
      "code": "SB",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sb\"></span><span>Solomon Islands</span>"
    },
    {
      "name": "Somalia",
      "code": "SO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-so\"></span><span>Somalia</span>"
    },
    {
      "name": "South Africa",
      "code": "ZA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-za\"></span><span>South Africa</span>"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "code": "GS",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gs\"></span><span>South Georgia and the South Sandwich Islands</span>"
    },
    {
      "name": "Spain",
      "code": "ES",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-es\"></span><span>Spain</span>"
    },
    {
      "name": "Sri Lanka",
      "code": "LK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-lk\"></span><span>Sri Lanka</span>"
    },
    {
      "name": "Sudan",
      "code": "SD",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sd\"></span><span>Sudan</span>"
    },
    {
      "name": "Suriname",
      "code": "SR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sr\"></span><span>Suriname</span>"
    },
    {
      "name": "Svalbard and Jan Mayen",
      "code": "SJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sj\"></span><span>Svalbard and Jan Mayen</span>"
    },
    {
      "name": "Swaziland",
      "code": "SZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sz\"></span><span>Swaziland</span>"
    },
    {
      "name": "Sweden",
      "code": "SE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-se\"></span><span>Sweden</span>"
    },
    {
      "name": "Switzerland",
      "code": "CH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ch\"></span><span>Switzerland</span>"
    },
    {
      "name": "Syrian Arab Republic",
      "code": "SY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-sy\"></span><span>Syrian Arab Republic</span>"
    },
    {
      "name": "Taiwan, Province of China",
      "code": "TW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tw\"></span><span>Taiwan, Province of China</span>"
    },
    {
      "name": "Tajikistan",
      "code": "TJ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tj\"></span><span>Tajikistan</span>"
    },
    {
      "name": "Tanzania, United Republic of",
      "code": "TZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tz\"></span><span>Tanzania, United Republic of</span>"
    },
    {
      "name": "Thailand",
      "code": "TH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-th\"></span><span>Thailand</span>"
    },
    {
      "name": "Timor-Leste",
      "code": "TL",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tl\"></span><span>Timor-Leste</span>"
    },
    {
      "name": "Togo",
      "code": "TG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tg\"></span><span>Togo</span>"
    },
    {
      "name": "Tokelau",
      "code": "TK",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tk\"></span><span>Tokelau</span>"
    },
    {
      "name": "Tonga",
      "code": "TO",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-to\"></span><span>Tonga</span>"
    },
    {
      "name": "Trinidad and Tobago",
      "code": "TT",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tt\"></span><span>Trinidad and Tobago</span>"
    },
    {
      "name": "Tunisia",
      "code": "TN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tn\"></span><span>Tunisia</span>"
    },
    {
      "name": "Turkey",
      "code": "TR",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tr\"></span><span>Turkey</span>"
    },
    {
      "name": "Turkmenistan",
      "code": "TM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tm\"></span><span>Turkmenistan</span>"
    },
    {
      "name": "Turks and Caicos Islands",
      "code": "TC",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tc\"></span><span>Turks and Caicos Islands</span>"
    },
    {
      "name": "Tuvalu",
      "code": "TV",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-tv\"></span><span>Tuvalu</span>"
    },
    {
      "name": "Uganda",
      "code": "UG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ug\"></span><span>Uganda</span>"
    },
    {
      "name": "Ukraine",
      "code": "UA",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ua\"></span><span>Ukraine</span>"
    },
    {
      "name": "United Arab Emirates",
      "code": "AE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ae\"></span><span>United Arab Emirates</span>"
    },
    {
      "name": "United Kingdom",
      "code": "GB",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-gb\"></span><span>United Kingdom</span>"
    },
    {
      "name": "United States",
      "code": "US",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-us\"></span><span>United States</span>"
    },
    {
      "name": "United States Minor Outlying Islands",
      "code": "UM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-um\"></span><span>United States Minor Outlying Islands</span>"
    },
    {
      "name": "Uruguay",
      "code": "UY",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-uy\"></span><span>Uruguay</span>"
    },
    {
      "name": "Uzbekistan",
      "code": "UZ",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-uz\"></span><span>Uzbekistan</span>"
    },
    {
      "name": "Vanuatu",
      "code": "VU",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vu\"></span><span>Vanuatu</span>"
    },
    {
      "name": "Venezuela",
      "code": "VE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ve\"></span><span>Venezuela</span>"
    },
    {
      "name": "Viet Nam",
      "code": "VN",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vn\"></span><span>Viet Nam</span>"
    },
    {
      "name": "Virgin Islands, British",
      "code": "VG",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vg\"></span><span>Virgin Islands, British</span>"
    },
    {
      "name": "Virgin Islands, U.S.",
      "code": "VI",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-vi\"></span><span>Virgin Islands, U.S.</span>"
    },
    {
      "name": "Wallis and Futuna",
      "code": "WF",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-wf\"></span><span>Wallis and Futuna</span>"
    },
    {
      "name": "Western Sahara",
      "code": "EH",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-eh\"></span><span>Western Sahara</span>"
    },
    {
      "name": "Yemen",
      "code": "YE",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-ye\"></span><span>Yemen</span>"
    },
    {
      "name": "Zambia",
      "code": "ZM",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-zm\"></span><span>Zambia</span>"
    },
    {
      "name": "Zimbabwe",
      "code": "ZW",
      "countryWithFlag": "<span class=\"mr-2 pt-1 fi fi-zw\"></span><span>Zimbabwe</span>"
    }
  ];

  constructor() { }

  getCountryDetails() {
    return this.countryCode
  }
}
