import { WebsocketService } from 'app/shared/services/core';
import { Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomerListService, DeviceListService } from 'app/shared/services/comp';

@Injectable({
    providedIn: 'root',
})
export class DashbaordDataService {
    public dataStream: Subject<any> = new Subject();

    wsDataStreamSubscription: Subscription;

    constructor(
        private wsService: WebsocketService,
        private customerService: CustomerListService,
        private deviceService: DeviceListService,
    ) {
        this.streamData();
    }

    streamData() {
        this.wsDataStreamSubscription = this.wsService.getDataEmitter().subscribe(
            (res) => {
                this.dataStream.next(res);
            },
            (err) => {
                this.fallBackHttp();
            },
            () => {},
        );
    }

    fallBackHttp() {
        let data = { tenant_id: 11, network_id: '' };
        let deviceList = this.deviceService.getDeviceList(data).toPromise();
        let tenantInfo = this.customerService.getTenantInfo(data).toPromise();
        Promise.all([deviceList, tenantInfo]).then((res) => {});
    }
}
