<div class="modal-header" style="width:480px; overflow-x: hidden" >
    <div *ngIf="isEdit"  class="modal-title">Edit Issue</div>
    <div *ngIf="!isEdit"  class="modal-title">View Issue</div>
    <button type="button" class="close" data-dismiss="modal" mat-dialog-close aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body" style="overflow-x:hidden" >
  
    
   <form [formGroup]="ticketRaiseForm" class="ml-2" >
      <div class="form-row">
        <div class="form-group col-md-6" >
          <label>Issue in : 
          </label>
          <select 
              class="form-control form-control-sm col-md-10" 
              formControlName="issue_in"               >
              <option value="common" >common</option>
              <option *ngFor="let item of devices"  [value]="item.name" >{{item.name}}</option>
            </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6" >
          <label>Issue
          </label>
          <input [readonly]= "!isEdit" formControlName="issue_sub"  type="text" class="form-control form-control-md col-md-10" 
          [ngClass]="{'is-valid': !ticketRaiseForm.controls.issue_sub.invalid && ticketRaiseForm.controls.issue_sub.touched ,'is-invalid':ticketRaiseForm.controls.issue_sub.invalid && ticketRaiseForm.controls.issue_sub.touched}"
           placeholder="Issue Name">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-8" >
          <label>Description
          </label>
          <textarea [readonly]= "!isEdit" formControlName="issue_desc" rows=5  class="form-control form-control-md "          
           placeholder="Detailed Description of the issue"></textarea>

        </div>
      </div>
    </form>

      <div *ngIf="!isEdit"  class="form-row">
          <div class="form-group col-md-8" >
            <label>Resolved Description
            </label>
            <textarea readonly [(ngModel)]="resolvedDescription" class="form-control form-control-md "></textarea>
  
          </div>
        </div>


      <div *ngIf="isEdit"  class="row" style="margin-top: 10px">
        <div  class="col">
          <button (click)="save()" class="btn btn-outline-primary" style="float:right;">
            Save <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw"></i>

          </button>
        </div>
      </div>

  </div>