import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { DeviceListService, CustomerListService } from 'app/shared/services/comp';
import { GlobalConstants } from 'app/configs/constants';
import { UserService, CommonService, WebsocketService, GlobalService } from 'app/shared/services/core';
import { HttpRequestCancelService, AlertService, StorageService } from 'app/shared/services/misc';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NetworkListService } from 'app/store/services/network-list.service';
import { allNetworks } from 'app/store/state/network.state';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    accountActivateParams;
    constructor(
        private http: HttpClient,
        private deviceListService: DeviceListService,
        private global: GlobalService,
        private globalConstants: GlobalConstants,
        private userService: UserService,
        private handler: HttpBackend,
        private httpCancel: HttpRequestCancelService,
        private alert: AlertService,
        private customerListService: CustomerListService,
        private networkListService: NetworkListService,
        private wsService: WebsocketService,
        private storage: StorageService,
        private router: Router,
    ) {
        this.http = new HttpClient(handler); // To remove authorization header
    }

    clerInterval;
    logOutCount = 0;

    login(email: string, password: string, remember: boolean) {
        const user = {
            username: email,
            password: password,
            remember: remember,
        };
        this.global.username.next(email);
        return this.http.post(this.globalConstants.LOGIN_URL, user).pipe(
            catchError((err) => {
                this.global.handleError(err);
                return throwError(err);
            }),
        );
    }

    loginsso(url) {
        return this.http.get(url);
    }

    activate(data) {
        return this.http.post(this.globalConstants.TENANT_ACTIVATE, data);
    }

    validateOtp(emperalToken, code, remember) {
        const data = {
            ephemeral_token: emperalToken,
            code: code,
            remember: remember,
        };
        return this.http.post(this.globalConstants.MFALOGIN_URL, data).pipe(
            catchError((err) => {
                this.global.handleError(err);
                return throwError(err);
            }),
        );
    }

    socialLogin(id_token: string, method: string) {
        const data = {
            id_token: id_token,
            socialtype: method,
        };
        return this.http.post(this.globalConstants.GOOGLE_LOGIN, data).pipe(
            catchError((err) => {
                console.log(err);
                this.global.handleError(err);
                return throwError(err);
            }),
        );
    }

    logout() {
        this.userService.deleteCookie('cosgridToken');
        this.logOutCount++;

        this.storage.setRememberMe(false);
        this.wsService.terminateConnection();
        // remove the select tenant id when the user logs out to avoid tenant id mismatch when loging out as one user and logging in again as another user
        this.customerListService.selectedTenantId = undefined;
        this.customerListService.selectedTenantName = undefined;
        [
            'tenant',
            'tenantName',
            'refresh',
            'tenantID',
            'listOfDevices',
            'selectedNetwork',
            'selectedCustomer',
            'listOfCustomers',
            'grafanaInterface',
            'network',
            'template',
            'isTenantSelected',
            'selectedTemplate',
        ].forEach((key) => {
            try {
                localStorage.removeItem(key);
            } catch (error) {
                console.error(error);
            }
        });

        this.customerListService.dispatchSelectedCusomtersToStore({}); // empty selected tenant
        this.deviceListService.dispatchSelectedDeviceToStore({}); // empty selected device

        // this.networkListService.dispatchSelectedNetworkToStore(allNetworks)

        this.userService.clearUser();

        this.router.navigateByUrl('/auth/login').then(() => {
            this.global.updateAuthStatus();
        });

        this.LogoutCall().subscribe(
            (res) => {
                this.httpCancel.cancelPendingRequests();
                this.alert.dismissCurrent();
                this.router.navigateByUrl('/auth/login').then(() => {
                    this.global.updateAuthStatus();
                }); // to dissmiss any snack thats in the screen after user logs out
            },
            (err) => {
                console.log(err);
                this.httpCancel.cancelPendingRequests();
                this.alert.dismissCurrent();
                this.router.navigateByUrl('/auth/login').then(() => {
                    this.global.updateAuthStatus();
                }); // to dissmiss any snack thats in the screen after user logs out
            },
        );
    }

    sessionTimeout() {
        this.deviceListService.dispatchSelectedDeviceToStore({});
        this.userService.clearUser();
        this.global.updateAuthStatus();
    }
    rememberMe(refresh) {
        return this.http.post(`${this.globalConstants.REMEMEBER_ME}`, {
            refresh,
        });
    }

    // isOnline() {
    //   return this.http.head(this.globalConstants.tenant_tenant+'cosgrid-com-mail/')
    // }

    LogoutCall(options?): Observable<any> {
        const endpoint = this.globalConstants.LOGOUT_URL;
        return this.http.post(endpoint, {});
    }

    loggedOutAtleastOnce = () => (this.logOutCount > 0 ? true : false);
}
